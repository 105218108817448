import React from 'react';
import BotonNotasPdf from '../components/notas/BotonNotasPdf';
import Container from 'react-bootstrap/Container';

export const NotasPdfPage = () => {
  return (
    <>
     <Container fluid>
          <BotonNotasPdf/>

        </Container>
       
       
    </>
   
  )
}
