import React, { useState } from "react";
import { Button, Card, Form } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { startMarcarCancelada } from "../../middlewares/tareas";

const CancelarTarea = ({ tareaId, setMostrarCancelar }) => {
  const dispatch = useDispatch();
  const [observaciones, setObservaciones] = useState("");

  const handleSubmit = () => {
    setMostrarCancelar(false);
    dispatch(startMarcarCancelada(tareaId, observaciones));
  };

  return (
    <Card bg="danger" text="white">
      <Card.Body>
        <Card.Title>Cancelar tarea</Card.Title>
        <Form autoComplete="off" onSubmit={handleSubmit}>
          <Form.Group className="mb-3">
            <Form.Control
              type="text"
              value={observaciones}
              onChange={({ target }) => setObservaciones(target.value)}
              placeholder="Observaciones"
            />
          </Form.Group>
          <Button variant="secondary" onClick={handleSubmit}>
            Cancelar
          </Button>
        </Form>
      </Card.Body>
    </Card>
  );
};

export default CancelarTarea;
