import Swal from "sweetalert2";
import {
  cargarTareas,
  getContadorTareas,
  marcarCancelada,
  marcarPospuesta,
  marcarTerminada,
  nuevaTarea,
  reasignarTarea,
  rehabilitarTarea,
} from "../actions/tareasActions";
import {
  crearHistoricoCancelada,
  crearHistoricoPospuesta,
  crearHistoricoTerminada,
  createTarea,
  getCountTareasPendientes,
  getCountTareasSinHacerUsuario,
  getTareas,
  getTareasSinHacerUsuario,
  postRehabilitarTarea,
  putReasignarTarea,
} from "../services/tareasServices";

export const cargarContadorTareas = (ambito, id) => {
  return async (dispatch) => {
    const contador = await getCountTareasPendientes(ambito, id);
    dispatch(getContadorTareas(contador));
  };
};

export const cargarContadorTareasSinHacer = () => {
  return async (dispatch) => {
    const contador = await getCountTareasSinHacerUsuario();
    dispatch(getContadorTareas(contador));
  };
};

export const startLoadingTareas = (ambito, id) => {
  return async (dispatch) => {
    const tareas = await getTareas(ambito, id);
    dispatch(cargarTareas(tareas));
  };
};

export const startLoadingTareasSinHacer = (fecha) => {
  return async (dispatch) => {
    const tareas = await getTareasSinHacerUsuario(fecha);
    dispatch(cargarTareas(tareas));
  };
};

export const startCrearTarea = (nota, limpiarFormulario) => {
  return async (dispatch) => {
    try {
      waitingAlert();
      // IMPORTANTE: Como dentro de createNota hago modificaciones la nota, desestructuro para mandar una instancia nueva.
      // De esta forma no modifico el estado del reducer de forma "ilegal"
      const {
        tarea: tareaCreada,
        ok,
        error: errorApi,
      } = await createTarea({ ...nota });

      if (ok) {
        dispatch(nuevaTarea(tareaCreada));
        alertaTareaCreadaCorrectamente();
        limpiarFormulario();
      } else {
        console.error("Error al crear la tarea", errorApi);
        alertaErrorTarea();
      }
    } catch (error) {
      console.error("Error al crear la tarea", error);
      alertaErrorTarea();
    }
  };
};

export const startMarcarPosponer = (id, fechaCaducidad, observaciones) => {
  return async (dispatch) => {
    const ok = await crearHistoricoPospuesta(id, fechaCaducidad, observaciones);

    if (ok) {
      dispatch(marcarPospuesta(id, fechaCaducidad, observaciones));
    }
  };
};

export const startMarcarHecha = (id) => {
  return async (dispatch) => {
    const ok = await crearHistoricoTerminada(id);

    if (ok) {
      dispatch(marcarTerminada(id));
      alertaTareaTerminada();
    }
  };
};

export const startMarcarCancelada = (id, observaciones) => {
  return async (dispatch) => {
    const ok = await crearHistoricoCancelada(id, observaciones);

    if (ok) {
      dispatch(marcarCancelada(id, observaciones));
      alertaTareaCancelada();
    }
  };
};

export const startReasignarTarea = (id, destinatarios) => {
  return async (dispatch) => {
    const ok = await putReasignarTarea(id, destinatarios);

    if (ok) {
      dispatch(reasignarTarea(id, destinatarios));
      alertaTareaReasignada();
    }
  };
};

export const startRehabilitarTarea = (id) => {
  return async (dispatch) => {
    const ok = await postRehabilitarTarea(id);

    if (ok) {
      dispatch(rehabilitarTarea(id));
      alertaTareaRehabilitada();
    }
  };
};

const alertaTareaReasignada = () => {
  Swal.fire({
    title: "Tarea reasignada",
    timer: 500,
    showConfirmButton: false,
    icon: "success",
  });
};

const alertaTareaTerminada = () => {
  Swal.fire({
    title: "Tarea terminada",
    timer: 750,
    showConfirmButton: false,
    icon: "success",
  });
};

const alertaTareaCancelada = () => {
  Swal.fire({
    title: "Tarea cancelada",
    timer: 750,
    showConfirmButton: false,
    icon: "success",
  });
};

const alertaTareaRehabilitada = () => {
  Swal.fire({
    title: "Tarea rehabilitada",
    timer: 750,
    showConfirmButton: false,
    icon: "success",
  });
};

const alertaTareaCreadaCorrectamente = () => {
  Swal.fire("Éxito", `Tarea creada correctamente.`, "success");
};

const alertaErrorTarea = () => {
  Swal.fire("Error", `Se ha producido un error al enviar la tarea.`, "error");
};

const waitingAlert = () => {
  Swal.fire({
    title: "Enviado tarea...",
    //html: 'data uploading',// add html attribute if you want or remove
    allowOutsideClick: false,
    onBeforeOpen: () => {
      Swal.showLoading();
    },
  });
};
