import { notasTypes } from "../types/notasTypes"

export const initialState = {
    mostrarFormulario: false,
    edicion: false,
    cargando: true,
    notas: [],
    grupo:{
        "id": 5,
        "nombre": "GLOBAL"},
    contadorNotasPendientes:0,
    notaActiva: {
        seccionId: "01",
        referenciaId: "",
        tipo: "S",
        destinatarios: [],
        fechaEnvio: "",
        texto: "",
        privado: false,
        leida: false,
        email: [],
        grupoId: 5,
       // grupo: 'GLOBAL',
        grupoSelected: { value:5, label:"GLOBAL"}
    },
    notaSelected: null
}

export const notasReducer = (state = initialState, action) => {
    switch (action.type) {
        case notasTypes.cargando:
            return {
                ...state,
                cargando: true
            }
        case notasTypes.mostrarFormulario:
            return {
                ...state,
                mostrarFormulario: true,
                edicion: false,
                notaActiva: initialState.notaActiva
            }
        case notasTypes.ocultarFormulario:
            return {
                ...state,
                edicion: false,
                mostrarFormulario: false
            }
        case notasTypes.mostrarFormularioResponder:
            const notaActiva =  state.notas.find(nota => nota.id === action.payload);
            const notaResponder = { ...notaActiva }
            notaResponder.texto = "";
            notaResponder.fechaEnvio = new Date();
            notaResponder.destinatarios = [
                {
                    value: notaResponder.remitente,
                    label: `${notaResponder.remitente} - ${notaResponder.de}`
                }
            ];

            return {
                ...state,
                edicion: false,
                mostrarFormulario: true,
                notaActiva: notaResponder
            }
        case notasTypes.mostrarFormularioEdicion:
            const notaEdicion = state.notas.find(nota => nota.id === action.payload);
            notaEdicion.fechaEnvio = notaEdicion.fechaEnvio || null;

            return {
                ...state,
                edicion: true,
                mostrarFormulario: true,
                notaActiva: notaEdicion
            }
        case notasTypes.cargarNotas:
            return {
                ...state,
                cargando: false,
                notas: action.payload,
            }
        case notasTypes.nuevaNota:
            return {
                ...state,
                notas: [action.payload, ...state.notas],
                contadorNotasPendientes: ++state.contadorNotasPendientes
            }
        case notasTypes.actualizarNota:
            return {
                ...state,
                notas: state.notas.map(nota => {
                    if (nota.id === action.payload.id) {
                        return {
                            ...nota,
                            destinatarios: action.payload.destinatarios,
                            texto: action.payload.texto,
                            privado: action.payload.privado,
                            email: action.payload.email,
                            grupoId: action.payload.grupoId,
                            fechaEnvio: action.payload.fechaEnvio
                        };
                    }

                    return nota;
                })
            }
        case notasTypes.actualizarNotaActiva:
            return {
                ...state,
                notaActiva: action.payload
            }
        case notasTypes.marcarLeida:
            return {
                ...state,
                notas: state.notas.map(nota => {
                    if (nota.id === action.payload) {
                        nota.leida = true;
                    }

                    return nota;
                }),
                contadorNotasPendientes: state.contadorNotasPendientes - 1
            }
        case notasTypes.marcarNoLeida:
            return {
                ...state,
                notas: state.notas.map(nota => {
                    if (nota.id === action.payload) {
                        nota.leida = false;
                    }

                    return nota;
                }),
                contadorNotasPendientes: state.contadorNotasPendientes + 1
            }
        case notasTypes.borrarNota:
            return {
                ...state,
                notas: state.notas.filter(nota => nota.mensajeId !== action.payload),
            }
        case notasTypes.selectedNota:
            return {
                ...state,
                notaSelected: action.payload.id
            }
        case notasTypes.seleccionarNotaSiguiente:
            const notasVisibles = state.notas.filter(t => t.show);

            const notaSeleccioanda = state.notas.find(n => n.id === state.notaSelected);
            const notaSeleccionadaIndex = notasVisibles.indexOf(notaSeleccioanda);
            const siguienteNota = notasVisibles[notaSeleccionadaIndex + 1] ?? notaSeleccioanda;

            return {
                ...state,
                notaSelected: siguienteNota.id
            }
        case notasTypes.seleccionarNotaAnterior:
            const notasVisibles2 = state.notas.filter(t => t.show);

            const notaSeleccioanda2 = state.notas.find(n => n.id === state.notaSelected);
            const notaSeleccionadaIndex2 = notasVisibles2.indexOf(notaSeleccioanda2);
            const anteriorNota = notasVisibles2[notaSeleccionadaIndex2 - 1] ?? notaSeleccioanda2;

            return {
                ...state,
                notaSelected: anteriorNota.id
            }
        case notasTypes.getContador:
            return {
                ...state,
                contadorNotasPendientes: action.payload
            }
        case notasTypes.actualizarContador:
            return {
                ...state,
                contadorNotasPendientes: action.payload 
            }
        case notasTypes.filtrarNotas:
            const filtros = action.payload;
            return {
                ...state,
                notas: aplicarFiltro(state.notas, filtros)
            }
        default: return state;
    }
}

const aplicarFiltro = (notas, filtros) => {
    const { 
        futurasActualesTodas,
        leidasNoleidasTodas, 
        privadasPublicas, 
        grupos,
        ambitos,
        secciones
    } = filtros;

    let notasFiltradas = futurasActualesTodas === 1 ? notas :
        futurasActualesTodas === 2 ? notas.filter(nota => nota.fechaEnvio <= new Date()) :
        notas.filter(nota => nota.fechaEnvio > new Date());

    notasFiltradas = leidasNoleidasTodas === 1 ? notasFiltradas :
        leidasNoleidasTodas === 2 ? notasFiltradas.filter(nota => nota.leida) :
        notasFiltradas.filter(nota => !nota.leida);
    
    notasFiltradas = privadasPublicas === 1 ? notasFiltradas :
        privadasPublicas === 2 ? notasFiltradas.filter(nota => nota.privado) :
        notasFiltradas.filter(nota => !nota.privado);

    if(grupos && grupos.length>0 ){
            const opcionesGrupos = grupos.map(s=>s.value);
            notasFiltradas = notasFiltradas.filter(nota => opcionesGrupos.includes(nota.grupo.id));
    }

    if(ambitos && ambitos.length>0 ){
        const opcionesOpciones = ambitos.map(s=>s.value);
        notasFiltradas = notasFiltradas.filter(nota => opcionesOpciones.includes(nota.ambito.id));
    }

    if(secciones && secciones.length>0 ){
        const seccionesOpciones = secciones.map(s=>s.value);
        notasFiltradas = notasFiltradas.filter(nota => seccionesOpciones.includes(nota.seccionId));
    }

    const notasFiltradasIds = notasFiltradas.map(t => t.id);
    
    return notas.map(t => ({
        ...t,
        show: notasFiltradasIds.includes(t.id)
    }));
}