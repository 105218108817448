import { authTypes } from "../types/authTypes"

const initialState = {
    isAuthenticated: false,
    usuarioId: '',
    nombre: '',
    roles: [],
    checking: true,
    pending: false,
    auth: '',
    sustitucionesNotas: []
}

export const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case authTypes.login:
            return  {
                ...action.payload,
                isAuthenticated: true,
                checking: false
            };
        case authTypes.loginFail:
            return {
                isAuthenticated: false,
                id: '',
                name: '',
                roles: [],
                checking: false
            };
        case authTypes.logout:
            return {
                isAuthenticated: false,
                id: '',
                name: '',
                roles: [],
                checking: false
            };
        case authTypes.setSustitucionesNotas:
            return {
                ...state,
                sustitucionesNotas: action.payload
            }
        default:
            return state;
    }
}