import React from 'react'
import { Form } from 'react-bootstrap'

const MensajeTexto = ({ texto, onChange }) => {
  return (
    <>
        <Form.Label>Anotación</Form.Label>
        <Form.Control 
            className='textAnotacion'
            as="textarea" 
            rows={5} 
            name="texto"
            value={texto}
            onChange={onChange}
        />
    </>
  )
}

export default MensajeTexto