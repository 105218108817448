import React, { useEffect, useState } from "react";
import Nav from "react-bootstrap/Nav";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useParams } from "react-router-dom";
import { cargarContadorNotas } from "../../middlewares/notas";
import { cargarContadorTareas } from "../../middlewares/tareas";
import { Toast, ToastContainer } from "react-bootstrap";

const Menu = () => {
  const { ambito, id } = useParams();
  const [show, setShow] = useState(true);
  const { contadorNotasPendientes } = useSelector((state) => state.notas);
  const { contadorTareasPendientes } = useSelector((state) => state.tareas);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(cargarContadorNotas(ambito, id));
  }, [dispatch, ambito, id]);

  useEffect(() => {
    dispatch(cargarContadorTareas(ambito, id));
  }, [dispatch, ambito, id]);

  return (
    <>
      <Nav variant="tabs" as="ul">
        <Nav.Item as="li">
          <NavLink
            to={`/notas/${ambito}/${id}`}
            activeClassName="selected"
            className={(isActive) => "nav-link" + (isActive ? " active" : "")}
          >
            {`Notas  (${contadorNotasPendientes})`}
          </NavLink>
        </Nav.Item>
        <Nav.Item as="li">
          <NavLink
            to={`/tareas/${ambito}/${id}`}
            activeClassName="selected"
            className={(isActive) => "nav-link" + (isActive ? " active" : "")}
          >
            {`Tareas (${contadorTareasPendientes})`}
          </NavLink>
        </Nav.Item>
      </Nav>
      {contadorTareasPendientes > 0 && (
        <ToastContainer className="p-3" position="top-start">
          <Toast
            onClose={() => setShow(false)}
            show={show}
            bg="info"
            delay={3000}
            autohide
          >
            <Toast.Header>
              <strong className="me-auto">Recordatorio</strong>
            </Toast.Header>
            <Toast.Body>Tiene TAREAS pendientes.</Toast.Body>
          </Toast>
        </ToastContainer>
      )}
    </>
  );
};

export default Menu;
