import React from 'react'
import Nav from 'react-bootstrap/Nav';
import Badge from 'react-bootstrap/Badge';
import format from 'date-fns/format';
import es from 'date-fns/locale/es';
import { useDispatch, useSelector } from 'react-redux';
import { marcarNotaSelected, seleccionarNotaAnterior, seleccionarNotaSiguiente } from '../../actions/notasActions';
import { startMarcarNotaLeida } from '../../middlewares/notas';
import NoLeida from './NoLeida';
import Privada from './Privada';
import { Col, Row } from 'react-bootstrap';
import EnviadoEmail from './EnviadoEmail';
import { getSustituciones } from '../../services/notasServices';

const NotasSelector = ({ nota, notaSelected }) => {
    
    const { usuarioId } = useSelector( state => state.auth);
    const fechaCreacion = format(nota.fecha, 'EEEE dd/MM/yyyy HH:mm', { locale: es });
    const dispatch = useDispatch();

    const handleSelected = (id) => {
        dispatch(marcarNotaSelected(id));
    };
    
    const handleDoubleClick = async ({ id, destinatarios, leida, fechaEnvio }) => {
        const sustituciones = await getSustituciones(usuarioId);
        const soyDestinatario = 
            destinatarios.some(destinatario => destinatario.value === usuarioId) ||
            destinatarios.some(destinatario => sustituciones.includes(destinatario.value) ||
            destinatarios.some(destinatario => destinatario.value === `${usuarioId.substring(0, 4)}00`) ||
            destinatarios.every(destinatario => destinatario.fechaBaja !== null));
        const sinDestinatario = destinatarios.length === 0;
        const fechaEnvioAlcanzada = fechaEnvio ? format(fechaEnvio, 'yyyy/MM/dd') <= format(new Date(), 'yyyy/MM/dd') : true;
    
        if(!leida && fechaEnvioAlcanzada && (soyDestinatario || sinDestinatario)){
            dispatch(startMarcarNotaLeida(id))
        }
    }

    const handleKeyDown = ({ key }) => {
        if (key === "ArrowUp") {
            dispatch(seleccionarNotaAnterior());
        } else if (key === "ArrowDown") {
            dispatch(seleccionarNotaSiguiente());
        }
    }

    const active= nota.id === notaSelected ? "activeSelected":"";
    return (
        <Nav.Item  
            className={`border-bottom bg-light ${active}`} 
            key={nota.id}  
            onClick={()=>handleSelected(nota.id)} 
            onDoubleClick={ ()=>handleDoubleClick(nota) } 
        >
            <Nav.Link 
                className="position-relative activeMarginPadingNotas"  
                eventKey={nota.id} 
                tabIndex="0"
                onKeyDown={handleKeyDown} 
            >
                <Row>
                    <Col xs={11}>
                        <h5 className='nota-header'>{nota.de}</h5>
                    </Col>
                    <Col>
                        {
                            !nota.leida && <NoLeida nota={ nota } />
                        }
                    </Col>
                </Row>

                <Row className="text-muted">
                    <Col md={6}>
                        <small> {nota.texto.substring(0, 100)} {nota.texto.length > 100 && "..."}</small>
                    </Col>
                    <Col className='text-end d-none d-sm-block'>
                        <small>{ `${fechaCreacion.charAt(0).toUpperCase() + fechaCreacion.slice(1)}` }</small>
                        <br />
                        {
                           nota.fechaEnvio && <small>{ `Fecha aviso: ${format(nota.fechaEnvio, 'dd/MM/yyyy', { locale: es })}` }</small> 
                        }
                    </Col>
                    <small className="text-muted text-end">{ `${nota.ambito.nombre}: ${nota.referenciaId}` }</small>
                </Row>

                <Row>
                    <Col xs={7}><Badge pill bg="secondary">{nota.grupo.nombre}</Badge></Col>
                    <Col className='text-end'>
                        {
                            nota.email && <EnviadoEmail id={ nota.id } email={ nota.email } />
                        }
                    </Col>
                    <Col className='text-end'>
                        {
                            nota.privado && <Privada nota={ nota } />
                        }
                    </Col>
                </Row>
            </Nav.Link>
        </Nav.Item>
    )
}

export default NotasSelector