import React from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import Select from 'react-select';
import { useDestinatarios } from '../../hooks/useDestinatarios';

const Destinatarios = ({ destinatariosSelected, handleDestinatarioSelect }) => {
    
    const { options } = useDestinatarios();
    
    return (
        <Form.Group as={Row}>
            <Form.Label column lg="2">Destinatario</Form.Label>
            <Col lg="10">
                <Select
                    autoFocus 
                    className="basic-single"
                    classNamePrefix="select"
                    isClearable={true}
                    isSearchable={true}
                    placeholder="Identificador o el Nombre del Destinatario" 
                    options={options}
                    name="destinatarios"
                    value={destinatariosSelected}
                    onChange={handleDestinatarioSelect}
                    isMulti={true}
                    noOptionsMessage={({inputValue}) => "No se encontraron resultados"}
                />
            </Col>
        </Form.Group>
  )
}

export default Destinatarios