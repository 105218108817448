import React from 'react';
import Button from 'react-bootstrap/Button';
import { useDispatch } from 'react-redux';
import { mostrarFormularioNuevaAPartirDeOtra } from '../../actions/tareasActions';

const BotonTareaNuevaDesdeOtra = ({ tareaId }) => {
    
    const dispatch = useDispatch();

    const handleClickTareaNueva = (id) => {
        dispatch(mostrarFormularioNuevaAPartirDeOtra(id));
    }

    return (
        <Button 
            variant="soliss"
            onClick={() => {handleClickTareaNueva(tareaId)}}
        >
            Nueva tarea a partir de esta
        </Button>
    )
}

export default BotonTareaNuevaDesdeOtra