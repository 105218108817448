import { notasTypes } from "../types/notasTypes";

export const cargando = () => ({
    type: notasTypes.cargando
})

export const mostrarFormularioNueva = () => ({
    type: notasTypes.mostrarFormulario
});

export const ocultarFormularioNueva = () => ({
    type: notasTypes.ocultarFormulario
});

export const mostrarFormularioResponder = (id) => ({
    type: notasTypes.mostrarFormularioResponder,
    payload: id
});

export const mostrarFormularioEdicion = (id) => ({
    type: notasTypes.mostrarFormularioEdicion,
    payload: id
});

export const cargarNotas = (notas) => ({
    type: notasTypes.cargarNotas,
    payload: notas
})

export const nuevaNota = (notasCreadas) => ({
    type: notasTypes.nuevaNota,
    payload: notasCreadas
})

export const marcarLeida = (id) => ({
    type: notasTypes.marcarLeida,
    payload: id
})

export const marcarNoLeida = (id) => ({
    type: notasTypes.marcarNoLeida,
    payload: id
})

export const eliminarNota = (id) => ({
    type: notasTypes.borrarNota,
    payload: id
})

export const marcarNotaSelected = (id) => ({
    type: notasTypes.selectedNota,
    payload: { id }
})

export const seleccionarNotaSiguiente = () => ({
    type: notasTypes.seleccionarNotaSiguiente
})

export const seleccionarNotaAnterior = () => ({
    type: notasTypes.seleccionarNotaAnterior
})

export const getContadorNotas = (contador)=>({
    type: notasTypes.getContador,
    payload: contador
})

export const filtrarNotas = (filtro)=>({
    type: notasTypes.filtrarNotas,
    payload: filtro
})

export const setContadorNotas = (contador)=>({
    type: notasTypes.actualizarContador,
    payload: contador
})

export const actualizarNota = (nota)=>({
    type: notasTypes.actualizarNota,
    payload: nota
})