import React from "react";
import Nav from "react-bootstrap/Nav";
import { useDispatch } from "react-redux";
import TareasSelector from "./TareasSelector";
import { ocultarFormularioNueva } from "../../actions/tareasActions";

export const TareasSelectores = ({ tareas, tareaSelected }) => {
  const dispatch = useDispatch();

  const handleNavClick = () => {
    dispatch(ocultarFormularioNueva());
  };

  return (
    <Nav
      className="border-top flex-column border-end"
      onSelect={handleNavClick}
    >
      {tareas
        .filter((t) => t.show)
        .map((tarea) => (
          <TareasSelector
            key={tarea.id}
            tarea={tarea}
            tareaSelected={tareaSelected}
          />
        ))}
    </Nav>
  );
};

export const TareasSelectoresMemorized = React.memo(TareasSelectores);
