import { useEffect, useState } from "react";
import { getUsuarios } from "../services/usuariosServices";

export const useDestinatarios = () => {
    const [options, setDestinatariosOptions] = useState([]);

    useEffect(() => {
        getUsuarios()
            .then(usuarios => {
                setDestinatariosOptions(usuarios);
            });
    }, []);

    return {
        options
    }
}