import React from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '../hooks/useQuery';
import { Route, Redirect } from 'react-router-dom';

export const PublicRoute = ({ isAuthenticated, component: Component, ...rest }) => {

    const query = useQuery();
    const redirect = query.get('returnUrl') ?? '/';

    return (
        <Route 
            { ...rest } 
            component={ (props) => (
                isAuthenticated 
                    ? <Redirect to={ redirect } />
                    : <Component { ...props } /> 
            )}
        />
    )
}

PublicRoute.propTypes = {
    isAuthenticated: PropTypes.bool.isRequired,
    component: PropTypes.func.isRequired
}