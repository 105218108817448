export const opcionesTipo = 
    {
        "S":"SINIESTRO",
        "R":"RECIBO",
        "G":"PAGO SINIESTROS",
        "P":"POLIZA",
        "E":"EVENTOS TELE",
        "C":"CONVENIOS DE COLECTIVO",
        "M":"MUTUALISTA",
        "O":"C.A.U"
    }
  