import React from 'react';
import Button from 'react-bootstrap/Button';
import { useDispatch } from 'react-redux';
import { startMarcarHecha } from '../../middlewares/tareas';

const BotonTerminar = ({ tareaId }) => {

    const dispatch = useDispatch();

    return (
        <Button 
            variant="soliss"
            onClick={() => {dispatch(startMarcarHecha(tareaId))}}
        >
            Terminar
        </Button>
    )
}

export default BotonTerminar