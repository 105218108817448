import * as signalR from '@microsoft/signalr';
import { useCallback, useEffect, useMemo } from 'react';
import { getTokenFromCookie } from '../services/authServices';

export const useSignalR = (serverPath) => {

    
    const connection = useMemo(() => {
        return new signalR.HubConnectionBuilder()
            .withUrl(serverPath, { 
                accessTokenFactory: () => {
                    const { token } =  getTokenFromCookie();
                    return token;
                },
                skipNegotiation: true,
                transport: signalR.HttpTransportType.WebSockets 
            })
            .withAutomaticReconnect()
            .configureLogging(signalR.LogLevel.Error)
            .build();
    }, [serverPath]);

    const disconnect = useCallback(() => {
        if (connection.state === signalR.HubConnectionState.Connected) {
            return connection.stop();
        }
    }, [connection]);

    useEffect(() => {
        let stopped = false;
        
        if (connection.state === signalR.HubConnectionState.Disconnected) {
            connection.start()
                .then(() => {
                    if (stopped) {
                        disconnect();
                    } 
                })
                .catch((err) => {
                    console.error(err.toString())
                });
        }
        

        return () => {
            stopped = true;
            disconnect();
        }
    
    }, [connection, disconnect])
        
    
    return {
        connection
    };
}