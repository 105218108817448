import React from 'react'
import Button from 'react-bootstrap/Button';

const BotonReasignar = ({ setMostrarReasignar }) => {

    const handleClickReasignar = () =>  {
        setMostrarReasignar(currentState => !currentState);
    };

    return (
        <Button 
            variant="soliss"
            onClick={() => {handleClickReasignar()}}
        >
            Reasignar
        </Button>
    )
}

export default BotonReasignar