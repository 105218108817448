import React from 'react'
import Nav from 'react-bootstrap/Nav';
import Badge from 'react-bootstrap/Badge';
import format from 'date-fns/format';
import es from 'date-fns/locale/es';
import { useDispatch } from 'react-redux';
import { marcarTareaSelected, seleccionarTareaAnterior, seleccionarTareaSiguiente } from '../../actions/tareasActions';
import { opcionesTipo } from '../../Data/opcionesTipo';
import OverlayTriggerCaducidad from './OverlayTriggerCaducidad';

const TareasSelector = ({ tarea, tareaSelected }) => {
    
    const dispatch = useDispatch();
    const fechaInicio = format(tarea.fechaInicio, 'dd/MM/yyyy', { locale: es });
    const fechaFin = format(tarea.fechaCaducidad, 'dd/MM/yyyy', { locale: es });

    const handleSelected = (id) => {
        dispatch(marcarTareaSelected(id));
    };

    const handleKeyDown = ({ key }) => {
        if (key === "ArrowUp") {
            dispatch(seleccionarTareaAnterior());
        } else if (key === "ArrowDown") {
            dispatch(seleccionarTareaSiguiente());
        }
    }

    const active = tareaSelected === tarea.id ? "activeSelected" : "";
    
    return (
        <Nav.Item 
            className={`border-bottom bg-light p-2 ${active}`} 
            key={tarea.id}  
            onClick={()=>handleSelected(tarea.id)} 
        >
            <Nav.Link 
                eventKey={tarea.id} 
                className="activeMarginPading" 
                tabIndex="0"
                onKeyDown={handleKeyDown} 
            >
                <div className="row justify-content-between " style={{marginTop:"-15px"}}>
                    <div className="col-7 nota-header">
                            <h5 >{tarea.de}</h5>
                    </div>
                   
                    <div className="col-4 text-end">
                        <OverlayTriggerCaducidad tarea={ tarea } />
                        <small className="text-muted">{ ` ${fechaInicio}-${fechaFin}`}</small>
                    </div>
                    <div className="col text-end">
                        <small className="text-muted">{opcionesTipo[tarea.tipo]}:{" "}
                        { tarea.referenciaId  }</small>
                    </div>
                </div>
                <p className="text-muted"><small>{tarea.texto.substring(0, 100)} {tarea.texto.length > 100 && "..."}</small></p>
                
                <div className="col ">
                        <Badge pill bg="secondary">{tarea.grupo.nombre}</Badge>
                    </div>
                
               <div className="row">
                    <div className="col ">
                        <Badge pill bg="secondary">{tarea.tipoTarea.nombre}</Badge>
                    </div>
                    <div className="col">
                        {
                            tarea.privado && <span><i className="fas fa-lock"></i></span>
                        }
                    </div>
                    <span className="col">
                        {
                             tarea.estadoActual
                        }
                    </span>

               </div>
                
             
            </Nav.Link>
           
           
        </Nav.Item>
    )
}

export default TareasSelector