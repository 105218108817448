import React, { Fragment } from 'react'

const RadioSolissItem = ({ name, value, onChange, option, idx }) => {
    
    return (
        <>
            <input 
                id={`${name}${idx}`}
                className="btn-check" 
                type="radio" 
                name={`${name}`} 
                checked={value === option.value} 
                onChange={() => onChange(option.value) } 
            />
            <label 
                htmlFor={`${name}${idx}`}
                className={`btn radioBtn ${value === option.value ? "active" : "notActive"}`}
            >
                {option.label}
            </label>
        </>
    )
}

export default RadioSolissItem
