import React from "react";
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { startMarcarNotaNoLeida } from "../../middlewares/notas";

const BotonMarcarNoLeida = ({ nota }) => {
  const dispatch = useDispatch();
  const { usuarioId } = useSelector((state) => state.auth);
  const soyDestinatario = nota.destinatarios.some(
    (destinatario) => destinatario.value === usuarioId
  );

  const marcarNotaNoLeida = (id) => {
    dispatch(startMarcarNotaNoLeida(id));
  };

  return (
    <>
      {nota.leida && soyDestinatario && (
        <Button
          variant="soliss"
          onClick={() => {
            marcarNotaNoLeida(nota.id);
          }}
        >
          Marcar como no leída
        </Button>
      )}
    </>
  );
};

export default BotonMarcarNoLeida;
