import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import Select from "react-select";
import { filtrarPorTiposTareas, startLoadingTiposOpcionesTareas } from '../../actions/filtrosActions';

const TiposTareas = () => {

    const dispatch = useDispatch();
    const {  tipos, tiposOpciones } = useSelector(state => state.filtros);

    const handleTipoIdSelect = (seleccion) => {
        dispatch(filtrarPorTiposTareas(seleccion));
    }

    useEffect(() => {
      
            dispatch(startLoadingTiposOpcionesTareas())
      
    }, [dispatch]);

    return (
        <Select
            className="basic-single"
            classNamePrefix="select"
            isClearable={true}
            isSearchable={true}
            placeholder={"Tipos de Tareas"}
            options={ tiposOpciones }
            name={"tipoFilter"}
            value={ tipos }
            onChange={(seleccion) => handleTipoIdSelect(seleccion)} //esTarea ? handleTipoTareaIdSelect(seleccion) : handleTipoAnotacionIdSelect(seleccion)}
            isMulti={true}
            noOptionsMessage={({inputValue}) => "No se encontraron resultados"}
        />
    )
}

export default TiposTareas