import React from "react";
import PropTypes from "prop-types";

import { Route } from "react-router-dom";
import AccesoDenegadoPage from "../pages/AccesoDenegadoPage";
import SolicitarAcceso from "../pages/SolicitarAcceso";

export const PrivateRoute = ({
  isAuthenticated,
  roles,
  component: Component,
  ...rest
}) => {
  const env = process.env.REACT_APP_ENV;
  return (
    <Route
      {...rest}
      component={(props) =>
        isAuthenticated &&
        !roles.includes(process.env.REACT_APP_ROL_ACCESO) &&
        env !== "development" ? (
          <SolicitarAcceso />
        ) : isAuthenticated ? (
          <Component {...props} />
        ) : (
          <AccesoDenegadoPage />
        )
      }
    />
  );
};

PrivateRoute.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  component: PropTypes.func.isRequired,
};
