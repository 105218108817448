import React from 'react'
import { Col, Row } from 'react-bootstrap'

const LinkExpediente = ({ url, ambito }) => {

    let urlTemplate = process.env.REACT_APP_URL_SECCION;

  return (
    <Row>
        <Col>
            <a 
                href={`${urlTemplate.replace('##URL', url)}`}
                target="_blank"
                rel="noopener noreferrer"
            >
                Ver {ambito}
            </a>
        </Col>
    </Row>
  )
}

export default LinkExpediente