import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import Button from 'react-bootstrap/Button'
import { mostrarFormularioNueva } from '../../actions/tareasActions';

const BotonTareaNueva = () => {

    const { mostrarFormulario } = useSelector(state => state.tareas);

    const dispatch = useDispatch();

    const handleNuevaClick = () => {
        dispatch(mostrarFormularioNueva());
    }

    return (
        <>
            {
                (!mostrarFormulario) &&
                    <Button
                        variant="soliss" 
                        size="lg"
                        onClick={handleNuevaClick}
                    >
                        <i className="fas fa-plus"></i> Tarea nueva
                    </Button>
            }
        </>
    )
}

export default BotonTareaNueva