import React from 'react'
import OverlayTrigger from 'react-bootstrap/esm/OverlayTrigger'
import Tooltip from 'react-bootstrap/esm/Tooltip'

const NoLeida = ({ nota }) => {
  return (
    <OverlayTrigger
        key={nota.id}
        placement="top"
        overlay={
            <Tooltip id={`tooltip-${nota.id}`}>
                Nota no leida
            </Tooltip>
        }
    >
        <span className="dot position-absolute top-0 end-0"></span>
    </OverlayTrigger>
  )
}

export default NoLeida