import { fetchConTokenYRefresh } from "./authServices";

export const getGrupos = async () => {
    const { data: grupos } = await fetchConTokenYRefresh("gestornotas/grupos");

    if(!grupos) return [];

    const gruposOptions = grupos.map(grupo => ({
        value: grupo.id,
        label: grupo.nombre
    }));

    return gruposOptions;
}
