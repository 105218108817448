import React, { useState } from "react";
import { useDispatch } from "react-redux";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import DatePicker from "react-datepicker";
import { startMarcarPosponer } from "../../middlewares/tareas";
import { Button, Card } from "react-bootstrap";

const PosponerTarea = ({ tarea, setMostrarPosponer }) => {
  const dispatch = useDispatch();
  const [observaciones, setObservaciones] = useState("");
  const [fechaCaducidad, setFechaCaducidad] = useState(tarea.fechaCaducidad);

  const handleFechaEnvioChange = (fecha) => {
    setFechaCaducidad(fecha);
  };

  const handleSubmit = () => {
    setMostrarPosponer(false);
    dispatch(startMarcarPosponer(tarea.id, fechaCaducidad, observaciones));
  };

  return (
    <Card bg="light">
      <Card.Body>
        <Card.Title>Posponer tarea</Card.Title>
        <Form autoComplete="off">
          <Form.Group className="mb-3">
            <Form.Label>Posponer hasta</Form.Label>
            <Col lg="2">
              <DatePicker
                className="form-control"
                locale="es"
                dateFormat="dd/MM/yyyy"
                selected={fechaCaducidad}
                minDate={tarea.fechaInicio}
                placeholderText={"Posponer hasta"}
                name="fechaCaducidad"
                value={fechaCaducidad}
                onChange={(ev) => handleFechaEnvioChange(ev)}
              />
            </Col>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Observaciones</Form.Label>
            <Form.Control
              type="text"
              value={observaciones}
              onChange={({ target }) => setObservaciones(target.value)}
              placeholder="Observaciones"
            />
          </Form.Group>
          <Button variant="soliss" onClick={handleSubmit}>
            Posponer
          </Button>
        </Form>
      </Card.Body>
    </Card>
  );
};

export default PosponerTarea;
