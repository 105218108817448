import React, { useEffect } from "react";
import { Switch } from "react-router-dom";
import { ConnectedRouter } from "connected-react-router";

import { PrivateRoute } from "./PrivateRoute";
import { PublicRoute } from "./PublicRoute";
import NotasPage from "../pages/NotasPage";
import TareasPage from "../pages/TareasPage";
import HomePage from "../pages/HomePage";

import { history } from "../store/store";
import NotasUsuarioPage from "../pages/NotasUsuarioPage";
import TareasUsuarioPage from "../pages/TareasUsuarioPage";
import { NotasPdfPage } from "../pages/NotasPdfPage";
import AccesoDenegadoPage from "../pages/AccesoDenegadoPage";
import { useDispatch, useSelector } from "react-redux";
import { startRefreshToken } from "../middlewares/auth";
import { querystring } from "../helpers/querystring";
import { setCookieToken } from "../services/authServices";

const AppRouter = () => {
  const dispatch = useDispatch();
  const { checking, isAuthenticated, roles } = useSelector(
    (state) => state.auth
  );

  useEffect(() => {
    const token = querystring.token;
    const refreshToken = querystring.refreshToken;

    if (token && refreshToken) {
      setCookieToken(token, refreshToken);
    }

    const returnUrl = querystring.returnUrl;
    dispatch(startRefreshToken(returnUrl));
  }, [dispatch]);

  if (checking) {
    return <div>Cargando...</div>;
  }

  return (
    <ConnectedRouter history={history}>
      <div>
        <Switch>
          <PublicRoute
            exact
            path="/accesodenegado"
            isAuthenticated={isAuthenticated}
            component={AccesoDenegadoPage}
          />

          <PrivateRoute
            exact
            path="/notas/:ambito/:id"
            isAuthenticated={isAuthenticated}
            roles={roles}
            component={NotasPage}
          />
          <PrivateRoute
            exact
            path="/notas/exportar"
            isAuthenticated={isAuthenticated}
            roles={roles}
            component={NotasPdfPage}
          />

          <PrivateRoute
            exact
            path="/tareas/:ambito/:id"
            isAuthenticated={isAuthenticated}
            roles={roles}
            component={TareasPage}
          />
          <PrivateRoute
            exact
            path="/notasUsuario"
            isAuthenticated={isAuthenticated}
            roles={roles}
            component={NotasUsuarioPage}
          />

          <PrivateRoute
            exact
            path="/tareasUsuario"
            isAuthenticated={isAuthenticated}
            roles={roles}
            component={TareasUsuarioPage}
          />

          <PrivateRoute
            path="/"
            isAuthenticated={isAuthenticated}
            roles={roles}
            component={HomePage}
          />
        </Switch>
      </div>
    </ConnectedRouter>
  );
};

export default AppRouter;
