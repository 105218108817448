import { fetchConTokenYRefresh } from "./authServices";

export const getSecciones = async () => {
    const { data: secciones } = await fetchConTokenYRefresh("gestornotas/secciones");

    if(!secciones) return [];

    const seccionesOptions = secciones.map(seccion => ({
        value: seccion.id,
        label: seccion.nombre
    }));

    return seccionesOptions;
}
