import { fetchConTokenYRefresh } from "./authServices";

export const getAmbitos = async (signal) => {
    const { ok, data: ambitos } = await fetchConTokenYRefresh("gestornotas/ambitos", null, 'GET', signal);
    
    if(!ok) return [];
    
    const ambitosOptions = ambitos.map(ambito => ({
        value: ambito.id,
        label: ambito.nombre
    }));

    return ambitosOptions;
}