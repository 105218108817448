import React from 'react'
import { Col, Row } from 'react-bootstrap'

export const DestinatariosPdf = ({destinatario}) => {
  return (
    <Row> 
        <Col lg="1"></Col>
        <Col lg="6"  >
            <small><b>Para:{" "}{destinatario.label}</b></small>
        </Col> 
    
        <Col  className="">
            <small className="">EXT.:{ destinatario.extension }</small>
        </Col>
    </Row>
  )
}
