import React, { useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Header from '../components/corporativo/Header';
import Ambitos from '../components/home/Ambitos';

const HomePage = () => {

    const [ambito, setAmbito] = useState({
        value: 'P',
        label: 'PÓLIZA'
    });

    const [referenciaId, setReferenciaId] = useState('');

    const handleAmbitoChange = (seleccion) => {
        setAmbito(seleccion);
    }

    const handleReferenciaChange = ({target}) => {
        setReferenciaId(target.value);
    }

    return (
        <>
            <Container fluid>
                <Header />
            </Container>
            <Container>
                <Row className='mt-2'>
                    <div className="d-grid gap-2">
                        <Button href="/notasUsuario" variant="soliss" size="lg">
                            MIS NOTAS
                        </Button>
                    </div>
                </Row>
                <Row className='mt-2'>
                    <div className="d-grid gap-2">
                        <Button href="/tareasUsuario" variant="soliss" size="lg">
                            MIS TAREAS
                        </Button>
                    </div>
                </Row>
                <Row className='mt-2'>
                    <Ambitos 
                        value={ambito} 
                        onChange={ handleAmbitoChange }
                    />
                </Row>
                <Row className='mt-2'>
                    <Col>
                        <Form.Control
                            type="text"
                            placeholder={ambito?.label}
                            onChange={handleReferenciaChange}
                        />
                    </Col>
                </Row>
                <Row className='mt-2'>
                    <Col>
                        <div className="d-grid gap-2">
                            <Button href={`/notas/${ambito?.value.toLowerCase()}/${referenciaId}`} variant="soliss" size="lg">
                                NOTAS DEL EXPEDIENTE
                            </Button>
                        </div>
                    </Col>
                    <Col>
                        <div className="d-grid gap-2">
                            <Button href={`/tareas/${ambito?.value.toLowerCase()}/${referenciaId}`} variant="soliss" size="lg">
                                TAREAS DEL EXPEDIENTE
                            </Button>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default HomePage
