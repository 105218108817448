import { getEstadosTareas, getTiposTareas } from "../services/tareasServices";
import { filtrosTypes } from "../types/filtrosTypes";

export const mostrarFormularioNueva = (nueva) => ({
  type: filtrosTypes.nueva,
  payload: nueva,
});

export const filtrarFuturasActualesTodas = (futurasActualesTodas) => ({
  type: filtrosTypes.futurasActualesTodas,
  payload: futurasActualesTodas,
});

export const filtrarLeidasNoLeidasTodas = (leidasNoleidasTodas) => ({
  type: filtrosTypes.leidasNoleidasTodas,
  payload: leidasNoleidasTodas,
});

export const filtrarPrivadasPublicas = (privadasPublicas) => ({
  type: filtrosTypes.privadasPublicas,
  payload: privadasPublicas,
});

export const filtrarEstadoTareas = (estadoActualId) => ({
  type: filtrosTypes.estadoTareas,
  payload: estadoActualId,
});

export const filtrarPorGrupos = (grupos) => ({
  type: filtrosTypes.grupos,
  payload: grupos,
});

export const filtrarPorEstadosTareas = (estadosTareas) => ({
  type: filtrosTypes.estadosTareas,
  payload: estadosTareas,
});

export const filtrarPorTiposTareas = (tipos) => ({
  type: filtrosTypes.tipos,
  payload: tipos,
});

export const filtrarPorAmbitos = (ambitos) => ({
  type: filtrosTypes.ambitos,
  payload: ambitos,
});

export const filtrarPorSecciones = (secciones) => ({
  type: filtrosTypes.secciones,
  payload: secciones,
});

export const startLoadingEstadosTareasOpciones = () => {
  return async (dispatch) => {
    const estadosTareasOptions = await getEstadosTareas();
    dispatch(establecerEstadosTareasOpciones(estadosTareasOptions));
  };
};

export const startLoadingTiposOpcionesTareas = () => {
  return async (dispatch) => {
    const tiposTareas = await getTiposTareas();

    dispatch(establecerTiposOpciones(tiposTareas));
  };
};
export const establecerTiposOpciones = (tiposOpciones) => ({
  type: filtrosTypes.tiposOpciones,
  payload: tiposOpciones,
});

export const establecerSeccionesOpciones = (seccionesOpciones) => ({
  type: filtrosTypes.seccionesOpciones,
  payload: seccionesOpciones,
});

export const establecerGruposOpciones = (gruposOpciones) => ({
  type: filtrosTypes.gruposOpciones,
  payload: gruposOpciones,
});

export const establecerEstadosTareasOpciones = (estadosTareasOpciones) => ({
  type: filtrosTypes.estadosTareasOpciones,
  payload: estadosTareasOpciones,
});

export const establecerAmbitosOpciones = (ambitosOpciones) => ({
  type: filtrosTypes.ambitosOpciones,
  payload: ambitosOpciones,
});

export const filtrarPorFecha = (fecha) => ({
  type: filtrosTypes.fecha,
  payload: fecha,
});

export const establecerModoLectura = (modoLectura) => ({
  type: filtrosTypes.modoLectura,
  payload: modoLectura,
});

export const establecerOficinaUsuario = (notasOficina) => ({
  type: filtrosTypes.notasOficina,
  payload: notasOficina,
});
