import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import Button from 'react-bootstrap/Button'
import { mostrarFormularioNueva } from '../../actions/notasActions';

const BotonNotaNueva = () => {

    const { mostrarFormulario } = useSelector(state => state.notas);
    const { modoLectura } = useSelector(state => state.filtros);

    const dispatch = useDispatch();

    const handleNuevaClick = () => {
        dispatch(mostrarFormularioNueva());
    }

    return (
        <>
            {
                (!mostrarFormulario && !modoLectura) &&
                    <Button
                        variant="soliss" 
                        size="lg"
                        onClick={handleNuevaClick}
                    >
                        <i className="fas fa-plus"></i> Nota nueva
                    </Button>
            }
        </>
    )
}

export default BotonNotaNueva