import React from 'react';
import ReactDOM from 'react-dom';
import NotasApp from './NotasApp';
import 'bootstrap/dist/css/bootstrap.min.css';

import './styles/base.css';
import './styles/solissRadioButton.css';
import './styles/hrstyles.css';

ReactDOM.render(
  <React.StrictMode>
    <NotasApp />
  </React.StrictMode>,
  document.getElementById('root')
);