import React from 'react'
import Button from 'react-bootstrap/Button';

const BotonPosponer = ({ setMostrarCalendario }) => {

    const handleClickMostrarCalendario = () => {
        setMostrarCalendario(currentState => !currentState);
    };
    
    return (
        <Button 
            variant="soliss"
            onClick={() => {handleClickMostrarCalendario()}}
        >
            Posponer
        </Button>
    )
}

export default BotonPosponer