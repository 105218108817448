import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { filtrarPrivadasPublicas } from '../../actions/filtrosActions';
import { opcionesPrivadas } from '../../options/opcionesPrivada';
import RadioSoliss from '../ui/RadioSoliss'

const PrivadasPublicas = () => {
    
    const dispatch = useDispatch();
    const { privadasPublicas } = useSelector(state => state.filtros);

    const handlePrivadasPublicasClick = (esTareaOption) => {
        dispatch(filtrarPrivadasPublicas(esTareaOption));
    }

    return (
        <RadioSoliss
            name="notasPrivadas" 
            value={ privadasPublicas } 
            onChange={handlePrivadasPublicasClick} 
            options={ opcionesPrivadas } 
        />
    )
}

export default PrivadasPublicas