import React, { useContext, useEffect, useState } from 'react'
import { Toast, ToastContainer } from 'react-bootstrap'
import { useDispatch } from 'react-redux';
import { SignalRContext } from '../../contexts/SignalRContext';

const Notificacion = () => {
    const dispatch = useDispatch();

    const [toastState, setToastState] = useState({
        show: false,
        bg: 'light',
        msg: ''
    });

    const { connection } = useContext(SignalRContext);

    useEffect(() => {
        connection.on('NotaCreada', () => {
            setToastState({
                show: true,
                bg: 'light',
                msg: 'Se ha creado una nota nueva.'
            });
        });

        return () => {
            connection.off('NotaCreada');
        }
    }, [connection, dispatch]);

    useEffect(() => {
        connection.on('MensajeEliminado', () => {
            setToastState({
                show: true,
                bg: 'light',
                msg: 'Se ha eliminado un registro.'
            });
        });

        return () => {
            connection.off('MensajeEliminado');
        }
    }, [connection, dispatch]);

    const hide = () => {
        setToastState({
            ...toastState,
            show: false
        });
    }

    return (
        <ToastContainer position="top-end" className="p-3">
            <Toast 
                bg={toastState.bg}  
                onClose={hide} 
                show={toastState.show} 
                delay={3000} 
                autohide
            >
                <Toast.Header>
                    <strong className="me-auto">Notificación</strong>
                </Toast.Header>
                <Toast.Body>{toastState.mensaje}</Toast.Body>
            </Toast>
        </ToastContainer>
    )
}

export default Notificacion