import React from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { logOut } from "../../actions/loginActions";
import { removeTokenCookie } from "../../services/authServices";

const LogoutButton = () => {
  const dispatch = useDispatch();
  const { usuarioId, nombre, isAuthenticated } = useSelector(
    (state) => state.auth
  );

  const handleLogout = () => {
    removeTokenCookie();
    dispatch(logOut());
  };

  if (!isAuthenticated) {
    return <></>;
  }

  return (
    <>
      <i className="fas fa-user"></i>
      {` ${usuarioId} - ${nombre?.toUpperCase()} | `}
      <button
        className="btn-styleless"
        alt="Salir"
        title="Salir"
        onClick={handleLogout}
      >
        <i className="fas fa-sign-out-alt"></i>
      </button>
    </>
  );
};

export default LogoutButton;
