import React from "react";
import { useSelector, useDispatch } from "react-redux";
import Tab from "react-bootstrap/Tab";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import format from "date-fns/format";
import es from "date-fns/locale/es";
import { startEliminarNota } from "../../middlewares/notas";
import {
  mostrarFormularioEdicion,
  mostrarFormularioResponder,
} from "../../actions/notasActions";
import LinkExpediente from "../shared/LinkExpediente";
import RemitenteDetinatarios from "../shared/RemitenteDetinatarios";
import { Link } from "react-router-dom";
import BotonMarcarNoLeida from "./BotonMarcarNoLeida";
import { Card } from "react-bootstrap";

const NotasContenido = ({ notasUsuario }) => {
  const dispatch = useDispatch();
  const { usuarioId, notas: notasHabilitadas } = useSelector(
    (state) => state.auth
  );
  const { notas } = useSelector((state) => state.notas);

  const handleClickNotaNueva = (id) => {
    dispatch(mostrarFormularioResponder(id));
  };

  return (
    <Tab.Content>
      {notas.map((nota) => {
        const notaDeHoy =
          format(nota.fecha, "yyyy/MM/dd") === format(new Date(), "yyyy/MM/dd");
        const notaDeUsuario = nota.remitente === usuarioId;
        const notaParaUsuario = nota.destinatarios.some(
          (destinatario) => destinatario.value === usuarioId
        );
        const notaSinEmail = !nota.email;
        const fechaEnvioAlcanzada = nota.fechaEnvio
          ? format(nota.fechaEnvio, "yyyy/MM/dd") <
            format(new Date(), "yyyy/MM/dd")
          : true;

        return (
          <Tab.Pane key={nota.id} eventKey={nota.id}>
            <Card>
              <Card.Header>
                {`${nota.ambito.nombre}: ${nota.referenciaId}`}
              </Card.Header>
              <Card.Body>
                <RemitenteDetinatarios
                  de={nota.usuarioRemitente}
                  para={nota.destinatarios}
                  email={nota.email}
                />
                <Row>
                  <Col xs={3}>
                    {nota.fechaEnvio && (
                      <div>
                        Fecha envío:{" "}
                        <small className="text-muted">{`${format(
                          nota.fechaEnvio,
                          "EEEE dd/MM/yyyy",
                          { locale: es }
                        )}`}</small>
                      </div>
                    )}
                  </Col>

                  <Col xs={2}>
                    {!nota.leida && notaDeUsuario && notaSinEmail && (
                      <Button
                        variant="soliss"
                        onClick={() => {
                          dispatch(mostrarFormularioEdicion(nota.id));
                        }}
                      >
                        Modificar
                      </Button>
                    )}
                  </Col>
                  <Col xs={2}>
                    {((notaDeUsuario &&
                      notaParaUsuario &&
                      !nota.leida &&
                      !fechaEnvioAlcanzada) ||
                      (notaDeHoy &&
                        notaDeUsuario &&
                        nota.destinatarios.length === 0) ||
                      //notaDeHoy &&
                      (notaDeUsuario &&
                        notaSinEmail &&
                        !nota.leida &&
                        !fechaEnvioAlcanzada)) && (
                      <Button
                        variant="soliss"
                        onClick={() => {
                          dispatch(startEliminarNota(nota.mensajeId));
                        }}
                      >
                        <i className="fas fa-trash-alt"></i> Eliminar
                      </Button>
                    )}
                  </Col>
                  <Col xs={2}>
                    <BotonMarcarNoLeida nota={nota} />
                  </Col>
                  <Col xs={3}>
                    {nota.leida && notasHabilitadas === "S" && (
                      <Button
                        variant="soliss"
                        onClick={() => {
                          handleClickNotaNueva(nota.id);
                        }}
                      >
                        Responder
                      </Button>
                    )}
                  </Col>
                </Row>
                {notasUsuario && nota.referenciaId && (
                  <Link
                    to={`/notas/${nota.ambito.id.toLowerCase()}/${
                      nota.referenciaId
                    }`}
                    target="_blank"
                  >
                    Ver notas del expediente
                  </Link>
                )}
                {nota.url && (
                  <LinkExpediente
                    url={nota.url}
                    ambito={nota.ambito.nombre.toLowerCase()}
                  />
                )}
                <hr />
                <div dangerouslySetInnerHTML={{ __html: nota.texto }} />
              </Card.Body>
            </Card>
          </Tab.Pane>
        );
      })}
    </Tab.Content>
  );
};

export default NotasContenido;
