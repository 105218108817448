import { tareasTypes } from "../types/tareasTypes";

export const initialState = {
  mostrarFormulario: false,
  cargando: true,
  tareas: [],
  grupo: {
    id: 5,
    nombre: "GLOBAL",
  },
  tipoTareaId: {},
  tipoTarea: {},
  contadorTareasPendientes: 0,
  tareaActiva: {
    texto: "",
    seccionId: "01",
    tipo: "S",
    referenciaId: "",
    fechaInicio: new Date(),
    fechaCaducidad: "",
    destinatarios: [],
    email: "",
    privado: false,
    grupoId: 5,
    estadoActualId: 1,
    estadoActual: "PENDIENTE",
    destinatariosSelected: [],
    grupoSelected: { value: 5, label: "GLOBAL" },
    observaciones: "",
  },
  tareaSelected: null,
};

export const tareasReducer = (state = initialState, action) => {
  switch (action.type) {
    case tareasTypes.mostrarFormulario:
      return {
        ...state,
        mostrarFormulario: true,
        tareaActiva: initialState.tareaActiva,
      };
    case tareasTypes.ocultarFormulario:
      return {
        ...state,
        mostrarFormulario: false,
      };
    case tareasTypes.mostrarFormularioNuevaAPartirDeOtra:
      const tareaActivaArray = state.tareas.find(
        (tarea) => tarea.id === action.payload
      );
      const tareaActiva = {
        ...tareaActivaArray,
        anotacion: tareaActivaArray.mensaje,
        de: null,
        para: null,
        mensaje: null,
        estadoActualId: 1,
        estadoActual: "PENDIENTE",
      };

      return {
        ...state,
        tareaActiva,
        mostrarFormulario: true,
      };
    case tareasTypes.cargarTareas:
      return {
        ...state,
        cargando: false,
        tareas: action.payload,
      };
    case tareasTypes.nuevaTarea:
      return {
        ...state,
        tareas: [action.payload, ...state.tareas],
        contadorTareasPendientes: ++state.contadorTareasPendientes,
      };
    case tareasTypes.actualizarTareaActiva:
      if (
        JSON.stringify(action.payload.destinatarios) !==
        JSON.stringify(state.tareaActiva?.destinatarios)
      ) {
        return {
          ...state,
          tareaActiva: {
            ...action.payload,
          },
        };
      } else {
        return {
          ...state,
          tareaActiva: action.payload,
        };
      }
    case tareasTypes.marcarTerminada:
      return {
        ...state,
        tareas: state.tareas.map((tarea) => {
          if (tarea.id === action.payload) {
            return {
              ...tarea,
              estadoActualId: 2,
              estadoActual: "TERMINADA",
            };
          } else {
            return tarea;
          }
        }),
        contadorTareasPendientes: --state.contadorTareasPendientes,
      };
    case tareasTypes.marcarCancelada:
      return {
        ...state,
        tareas: state.tareas.map((tarea) => {
          if (tarea.id === action.payload.id) {
            return {
              ...tarea,
              estadoActualId: 4,
              estadoActual: "CANCELADA",
              observaciones: action.payload.observaciones,
            };
          } else {
            return tarea;
          }
        }),
        contadorTareasPendientes: --state.contadorTareasPendientes,
      };
    case tareasTypes.marcarPospuesta:
      return {
        ...state,
        tareas: state.tareas.map((tarea) => {
          if (tarea.id === action.payload.id) {
            return {
              ...tarea,
              estadoActualId: 3,
              estadoActual: "POSPUESTA",
              fechaCaducidad: action.payload.fecha,
              observaciones: action.payload.observaciones,
            };
          } else {
            return tarea;
          }
        }),
      };
    case tareasTypes.selectedTarea:
      return {
        ...state,
        tareaSelected: action.payload.id,
      };
    case tareasTypes.seleccionarTareaSiguiente:
      const tareasVisibles = state.tareas.filter((t) => t.show);

      const tareaSeleccioanda = state.tareas.find(
        (n) => n.id === state.tareaSelected
      );
      const tareaSeleccionadaIndex = tareasVisibles.indexOf(tareaSeleccioanda);
      const siguienteTarea =
        tareasVisibles[tareaSeleccionadaIndex + 1] ?? tareaSeleccioanda;

      return {
        ...state,
        tareaSelected: siguienteTarea.id,
      };
    case tareasTypes.seleccionarTareaAnterior:
      const tareasVisibles2 = state.tareas.filter((t) => t.show);

      const tareaSeleccioanda2 = state.tareas.find(
        (n) => n.id === state.tareaSelected
      );
      const tareaSeleccionadaIndex2 =
        tareasVisibles2.indexOf(tareaSeleccioanda2);
      const anteriorTarea =
        tareasVisibles2[tareaSeleccionadaIndex2 - 1] ?? tareaSeleccioanda2;

      return {
        ...state,
        tareaSelected: anteriorTarea.id,
      };
    case tareasTypes.getContador:
      return {
        ...state,
        contadorTareasPendientes: action.payload,
      };
    case tareasTypes.reasignarTarea:
      return {
        ...state,
        tareas: state.tareas.map((tarea) => {
          if (tarea.id === action.payload.tareaId) {
            return {
              ...tarea,
              destinatarios: action.payload.destinatarios,
            };
          } else {
            return tarea;
          }
        }),
      };
    case tareasTypes.rehabilitarTarea:
      return {
        ...state,
        tareas: state.tareas.map((tarea) => {
          if (tarea.id === action.payload.tareaId) {
            return {
              ...tarea,
              estadoActualId: 1,
              estadoActual: "PENDIENTE",
            };
          } else {
            return tarea;
          }
        }),
      };
    default:
      return state;
  }
};
