import React from 'react'
import OverlayTrigger from 'react-bootstrap/esm/OverlayTrigger'
import Tooltip from 'react-bootstrap/esm/Tooltip'
import differenceInCalendarDays from 'date-fns/differenceInCalendarDays';

const OverlayTriggerCaducidad = ({ tarea }) => {
    const diasCaducidad = differenceInCalendarDays(tarea.fechaCaducidad, new Date());
    return (
        <OverlayTrigger
            key={tarea.id}
            placement="top"
            overlay={
                <Tooltip id={`tooltip-${tarea.id}`}>
                    {
                        diasCaducidad > 0 ? `Caduca en ${diasCaducidad} ${diasCaducidad > 1 ? 'días' : 'día'}` : "Caducada"
                    }
                </Tooltip>
            }
            >
            <span 
                style={{ 
                    marginLeft: "-20px", 
                    marginTop: "-10px", 
                    position:"relative",
                    color:  diasCaducidad > 7 ? "green": 
                        diasCaducidad <=7 && diasCaducidad >=3 ? "orange" : 
                        diasCaducidad < 3 && diasCaducidad > 0 ? "red" :
                        "grey"
                }}
                className= { diasCaducidad > 7 ? "fas fa-hourglass-start ":
                            diasCaducidad <=7 && diasCaducidad >=3 ?"fas fa-hourglass-half " :
                            "fas fa-hourglass-end "}>
            </span>
        </OverlayTrigger>
    )
}

export default OverlayTriggerCaducidad