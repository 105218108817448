import { establecerAmbitosOpciones, establecerGruposOpciones, establecerSeccionesOpciones } from "../actions/filtrosActions";
import { getAmbitos } from "../services/ambitoServices";
import { getGrupos } from "../services/gruposServices";
import { getSecciones } from "../services/seccionesServices";

export const startLoadingAmbitosOpcionesNotas = (signal) => {
    return async (dispatch) => {
        const ambitosOptions = await getAmbitos(signal)       
        const ambitos = ambitosOptions.filter(ambito=>ambito.value==='S' || ambito.value==='P' || ambito.value==='M');
        dispatch(establecerAmbitosOpciones(ambitos));
    }
}

export const startLoadingGruposOpciones = () => {
    return async (dispatch) => {
        const notasOptions = await getGrupos()
        dispatch(establecerGruposOpciones(notasOptions));
    }
}

export const startLoadingSeccionesOpciones = () => {
    return async (dispatch) => {
        const notasOptions = await getSecciones()
        dispatch(establecerSeccionesOpciones(notasOptions));
    }
}
