import { createContext } from "react";
import { useSignalR } from "../hooks/useSignalR";

const baseUrl = process.env.REACT_APP_SOLISSNET_API_URL;
export const SignalRContext = createContext();

export const SignalRNotasProvider = ({ children }) => {
  const { connection, online } = useSignalR(
    `${baseUrl}/gestornotas/hubs/notas`
  );

  return (
    <SignalRContext.Provider value={{ connection, online }}>
      {children}
    </SignalRContext.Provider>
  );
};
