import { fetchConTokenYRefresh } from "./authServices";
import { format, parseISO } from "date-fns";
import { ambitos } from "../Data/ambitos";

export const fechaNotaToDate = (fecha) => {
  if (!fecha) return "";

  const componentesFecha = fecha.split("/");
  const dia = componentesFecha[0];
  const mes = componentesFecha[1] - 1;
  const anio = componentesFecha[2];

  return new Date(anio, mes, dia);
};

export const getNotas = async (ambito, id) => {
  let notas = [];
  const { data } = await fetchConTokenYRefresh(
    `gestornotas/notas/${ambitos[ambito].url}/${id}`
  );

  if (data) {
    notas = data;
  }

  if (ambito === "p") {
    const { data: poliza } = await fetchConTokenYRefresh(`polizas/${id}`);

    if (
      poliza &&
      poliza.numeroPresupuesto &&
      poliza.numeroPresupuesto !== poliza.id &&
      poliza.numeroPresupuesto.trim() !== ""
    ) {
      const { data: notasPresupuesto } = await fetchConTokenYRefresh(
        `gestornotas/notas/${ambitos[ambito].url}/${poliza.numeroPresupuesto}`
      );
      notas = notas.concat(notasPresupuesto);
    }
  }

  if (!notas) return [];

  return mapNotas(notas);
};

export const getNotasUsuario = async ({ fecha, ofiNotas = false, signal }) => {
  const { data } = await fetchConTokenYRefresh(
    `gestornotas/notas?fechaConsulta=${format(
      fecha,
      "yyyy-MM-dd"
    )}&notasOficina=${ofiNotas}`,
    null,
    "GET",
    signal
  );

  if (!data) return [];

  return mapNotas(data);
};

export const getCountNotasUsuario = async () => {
  const { data } = await fetchConTokenYRefresh(`gestornotas/notas/count`);

  if (!data) return 0;

  return data.count;
};

export const getCountNotasNoLeidas = async (ambito, id) => {
  const { data } = await fetchConTokenYRefresh(
    `gestornotas/notas/${ambitos[ambito].url}/${id}/count`
  );

  if (!data) return 0;

  return data.count;
};

export const getNombreOficina = async () => {
  const { data } = await fetchConTokenYRefresh(`gestornotas/oficinas`);

  if (!data) return 0;
  return data.nombre;
};

export const createNota = async (nota) => {
  nota.fechaEnvio = nota.fechaEnvio
    ? format(nota.fechaEnvio, "yyyy-MM-dd")
    : null;
  nota.email =
    nota.email && nota.email.length > 0
      ? nota.email.map((e) => e.value).join(";")
      : null;
  nota.destinatarios = nota.destinatarios.map((d) => d.value);
  nota.remitente = null; // Lo establece el API con la sesión

  const {
    data: notaCreated,
    ok,
    error,
  } = await fetchConTokenYRefresh(`gestornotas/notas`, nota, "POST");

  return {
    nota: mapNota(notaCreated),
    ok,
    error,
  };
};

export const updateNota = async (nota) => {
  nota.fechaEnvio = format(nota.fechaEnvio, "yyyy-MM-dd");
  nota.email = nota.email ? nota.email.map((e) => e.value).join(";") : null;
  nota.destinatarios = nota.destinatarios.map((d) => d.value);

  const { ok } = await fetchConTokenYRefresh(
    `gestornotas/notas/${nota.id}`,
    nota,
    "PUT"
  );
  return ok;
};

export const marcarNotaComoLeida = async (id) => {
  const { ok } = await fetchConTokenYRefresh(
    `gestornotas/notas/marcar/${id}`,
    null,
    "PUT"
  );
  return ok;
};

export const marcarNotaComoNoLeida = async (id) => {
  const { ok } = await fetchConTokenYRefresh(
    `gestornotas/notas/desmarcar/${id}`,
    null,
    "PUT"
  );
  return ok;
};

export const borrarNota = async (mensajeId) => {
  const { ok } = await fetchConTokenYRefresh(
    `gestornotas/mensajes/${mensajeId}`,
    null,
    "DELETE"
  );
  return ok;
};

export const getSustituciones = async (usuarioId) => {
  const { ok, data } = await fetchConTokenYRefresh(
    `gestornotas/sustituciones/${usuarioId}`
  );

  if (!ok) {
    return [];
  }

  return data.sustituciones;
};

const mapNotas = (data) => {
  const notas = data.map(mapNota);
  return notas;
};

const mapNota = (nota) => {
  return {
    id: nota.id,
    leida: nota.leida,
    grupo: {
      id: nota.grupo.id,
      nombre: nota.grupo.nombre,
    },
    ambito: {
      id: nota.mensaje.ambito.id,
      nombre: nota.mensaje.ambito.nombre,
    },
    tipo: nota.mensaje.ambito.id,
    fechaEnvio: nota.fechaEnvio ? parseISO(nota.fechaEnvio) : null,
    fecha: parseISO(nota.mensaje.fechaCreacion),
    email: nota.mensaje.email
      ?.split(";")
      .map((email) => ({ value: email, label: email })),
    seccionId: nota.mensaje.seccionId,
    grupoId: nota.grupo.id,
    remitente: nota.mensaje.remitente,
    remitenteExtension: nota.mensaje.remitenteExtension,
    de: nota.mensaje.usuarioRemitente?.nombre,
    texto: nota.mensaje.texto,
    privado: nota.mensaje.privado,
    referenciaId: nota.mensaje.referenciaId,
    grupoSelected: {
      value: nota.grupo?.id,
      label: `${nota.grupo?.nombre}`,
    },
    usuarioRemitente: {
      value: nota.mensaje.usuarioRemitente.id,
      label: `${nota.mensaje.usuarioRemitente.id} - ${nota.mensaje.usuarioRemitente.nombre}`,
      extension: nota.mensaje.usuarioRemitente.extension,
    },
    destinatarios:
      nota.mensaje.destinatarios.length > 0
        ? nota.mensaje.destinatarios.map((destinatario) => ({
            value: destinatario.id,
            label: `${destinatario.id} - ${destinatario.nombre}`,
            extension: destinatario.extension,
            fechaBaja: destinatario.fechaBaja,
          }))
        : [],
    mensajeId: nota.mensaje.id,
    url: nota.url,
    show: true,
  };
};
