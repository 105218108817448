export const opcionesPrivada = [
    {
        value:true,
        label: 'Privada'
    },
    {
        value:false,
        label: 'Pública'
    }
]

export const opcionesPrivadas = [
    {
        value: 1,
        label: 'Todas'
    },
    {
        value: 2,
        label: 'Privadas'
    },
    {
        value: 3,
        label: 'Públicas'
    }
]