import Swal from "sweetalert2";
import { startOfTomorrow } from "date-fns";
import {
  actualizarNota,
  cargando,
  cargarNotas,
  eliminarNota,
  getContadorNotas,
  marcarLeida,
  marcarNoLeida,
  marcarNotaSelected,
  ocultarFormularioNueva,
} from "../actions/notasActions";
import {
  borrarNota,
  createNota,
  getCountNotasNoLeidas,
  getCountNotasUsuario,
  getNotas,
  getNotasUsuario,
  marcarNotaComoLeida,
  marcarNotaComoNoLeida,
  updateNota,
} from "../services/notasServices";

export const cargarContadorNotas = (ambito, id) => {
  return async (dispatch) => {
    const contador = await getCountNotasNoLeidas(ambito, id);
    dispatch(getContadorNotas(contador));
  };
};

export const cargarContadorNotasUsuario = (ambito, id) => {
  return async (dispatch) => {
    const contador = await getCountNotasUsuario();
    dispatch(getContadorNotas(contador));
  };
};

export const startLoadingNotas = (ambito, id) => {
  console.table({ ambito, id });

  return async (dispatch) => {
    dispatch(cargando());
    const notas = await getNotas(ambito, id);
    dispatch(cargarNotas(notas));
  };
};

export const startLoadingNotasUsuario = (fecha, signal) => {
  return async (dispatch) => {
    dispatch(cargando());
    const notas = await getNotasUsuario({ fecha, signal });
    dispatch(cargarNotas(notas));
  };
};

export const startLoadingNotasOficina = (fecha, signal) => {
  return async (dispatch) => {
    const notas = await getNotasUsuario({ fecha, ofiNotas: true, signal });
    dispatch(cargarNotas(notas));
  };
};

export const startCrearNota = (nota) => {
  return async (dispatch) => {
    waitingAlert();
    try {
      // IMPORTANTE: Como dentro de createNota hago modificaciones la nota, desestructuro para mandar una instancia nueva.
      // De esta forma no modifico el estado del reducer de forma "ilegal"
      const {
        nota: notaCreada,
        ok,
        error: errorApi,
      } = await createNota({ ...nota });

      if (ok) {
        alertaNotaCreadaCorrectamente();
        alertaEmailNoEnviado(nota);
        dispatch(ocultarFormularioNueva());
        dispatch(marcarNotaSelected(notaCreada.id));
      } else {
        console.error("Error al crear la nota", errorApi);
        alertaErrorNota();
      }
    } catch (error) {
      console.error("Error al crear la nota", error);
      alertaErrorNota();
    }
  };
};

export const startUpdateNota = (nota) => {
  return async (dispatch) => {
    waitingAlert();
    try {
      // IMPORTANTE: Como dentro de updateNota hago modificaciones la nota, desestructuro para mandar una instancia nueva.
      // De esta forma no modifico el estado del reducer de forma "ilegal"
      if (await updateNota({ ...nota })) {
        dispatch(actualizarNota(nota));
        alertaNotaActualizadaCorrectamente();
      } else {
        console.error("Error al actualizar la nota");
        alertaErrorNota();
      }
    } catch (error) {
      console.error("Error al actualizar la nota", error);
      alertaErrorNota();
    }
  };
};

export const startMarcarNotaLeida = (id) => {
  return async (dispatch) => {
    const ok = await marcarNotaComoLeida(id);

    if (ok) {
      dispatch(marcarLeida(id));
    }
  };
};

export const startMarcarNotaNoLeida = (id) => {
  return async (dispatch) => {
    const ok = await marcarNotaComoNoLeida(id);

    if (ok) {
      dispatch(marcarNoLeida(id));
    }
  };
};

export const startEliminarNota = (id) => {
  return async (dispatch) => {
    const ok = await borrarNota(id);

    if (ok) {
      dispatch(eliminarNota(id));
      alertaNotaBorradaCorrectamente();
    }
  };
};

const alertaNotaCreadaCorrectamente = () => {
  Swal.fire("Éxito", `Nota enviada correctamente.`, "success");
};

const alertaNotaBorradaCorrectamente = () => {
  Swal.fire("Éxito", `Nota borrada correctamente.`, "success");
};

const alertaNotaActualizadaCorrectamente = () => {
  Swal.fire("Éxito", `Nota actualizada correctamente.`, "success");
};

const alertaErrorNota = () => {
  Swal.fire("Error", `Se ha producido un error al enviar la nota.`, "error");
};

const alertaEmailNoEnviado = (nota) => {
  if (
    nota.fechaEnvio >= startOfTomorrow() &&
    nota.email &&
    nota.email.length > 0
  ) {
    Swal.fire("¡Atención!", "En notas futuras no se envía el email.", "info");
  }
};

const waitingAlert = () => {
  Swal.fire({
    title: "Enviando nota...",
    //html: 'data uploading',// add html attribute if you want or remove
    allowOutsideClick: false,
    onBeforeOpen: () => {
      Swal.showLoading();
    },
  });
};
