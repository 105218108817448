export const notasTypes = {
    cargando: 'NOTAS_CARGANDO',
    mostrarFormulario: 'NOTAS_MOSTRAR_FORMULARIO',
    ocultarFormulario: 'NOTAS_OCULTAR_FORMULARIO',
    mostrarFormularioResponder: 'NOTAS_MOSTRAR_FORMULARIO_RESPONDER',
    mostrarFormularioEdicion: 'NOTAS_MOSTRAR_FORMULARIO_EDICION',
    cargarNotas: 'NOTAS_CARGAR',
    nuevaNota: 'NOTAS_NUEVA',
    nuevasNotas: 'NOTAS_NUEVAS',
    actualizarNotaActiva: 'NOTAS_ACTUALIZAR_ACTIVA',
    actualizarNota: 'NOTAS_ACTUALIZAR',
    marcarLeida: 'NOTAS_MARCAR_LEIDA',
    marcarNoLeida: 'NOTAS_MARCAR_NO_LEIDA',
    borrarNota: 'NOTAS_BORRAR',
    selectedNota:'NOTAS_SELECTED',
    seleccionarNotaSiguiente:'NOTAS_SELECTED_SIGUIENTE',
    seleccionarNotaAnterior:'NOTAS_SELECTED_ANTERIOR',
    getContador:'NOTAS_CONTADOR',
    actualizarContador:'NOTAS_ACTUALIZAR_CONTADOR',
    filtrarNotas:'NOTAS_FILTRAR'
}