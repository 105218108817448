import { useEffect } from "react";
import { useParams } from 'react-router-dom';
import { useForm } from "./useForm";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { tareasTypes } from "../types/tareasTypes";
import { initialState } from "../reducers/tareasReducer";

export const useTareas = () => {

    const { ambito, id } = useParams();
    const dispatch = useDispatch();
    const { tareaActiva: tareaActivaReducer } = useSelector( state => state.tareas);
    const [ tareaActiva, handleInputChange, ,set ] = useForm({
        ...tareaActivaReducer,
        tipo: ambito || tareaActivaReducer.tipo,
        referenciaId: id || tareaActivaReducer.referenciaId,
    
    });

    const limpiar = () => {
        set({
            ...initialState.tareaActiva,
            grupoSelected: initialState.tareaActiva.grupoSelected,
            tipo: ambito || initialState.tareaActiva.tipo,
            referenciaId: id || initialState.tareaActiva.referenciaId,
        });
    }

    useEffect(() => {
        dispatch({
            type: tareasTypes.actualizarTareaActiva,
            payload: tareaActiva
        });
    }, [dispatch, tareaActiva])

    return {
        tarea: tareaActiva,
        handleInputChange,
        limpiar
    }
}