export const ambitos = {
    "p": {
        url: "p",
        nombre: 'Póliza'
    },
    "poliza": {
        url: "p",
        nombre: 'Póliza'
    },
    "s": { 
        url: "s",
        nombre: 'Siniestro'
    },
    "siniestro": { 
        url: "s",
        nombre: 'Siniestro'
    },
    "m": { 
        url:"m",
        nombre: "Mutualista"
    },
    "mutualista": { 
        url:"m",
        nombre: "Mutualista"
    },
    "r": {
        url:"r",
        nombre: "Recibo"
    },
    "recibo": {
        url:"r",
        nombre: "Recibo"
    },
    "j": {
        url:"j",
        nombre: "SAC"
    },
    "u": "u",
    "g": "g",
    "pagosSiniestros": "g",
    "e": "e",
    "eventos": "e",
    "c": "c",
    "convenios": "c",
    "o": "o",
    "presupuestos": "o"
}