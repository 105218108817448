import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Header from '../components/corporativo/Header';
import TareasMainUsuario from '../components/tareas/TareasMainUsuario';
import MenuUsuario from '../components/ui/MenuUsuario';
import { useSelector } from 'react-redux';
import TareasFiltrosUsuario from '../components/tareas/TareasFiltrosUsuario';
import { useEffect } from 'react';

const TareasUsuarioPage = () => {

    const { usuarioId, nombre } = useSelector( state => state.auth);

    useEffect(() => {
        document.title = `Tareas-${usuarioId}-${nombre}`;
    }, [usuarioId, nombre]);

    return (
        <Container fluid>
            <Header />

            <MenuUsuario />
            <Row>
                <Col sm={4} className="mt-3">
                    <h4 className='text-center'>{ `Tareas de ${nombre}` }</h4>
                </Col>
                <Col>
                    <div className="d-grid gap-2">
                        <TareasFiltrosUsuario />
                    </div>
                </Col>
            </Row>
            
               
            <TareasMainUsuario />
        </Container>
    )
}

export default TareasUsuarioPage
