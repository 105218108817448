import React, { useEffect } from 'react'
import Nav from 'react-bootstrap/Nav';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { cargarContadorNotasUsuario } from '../../middlewares/notas';
import { cargarContadorTareasSinHacer } from '../../middlewares/tareas';

const MenuUsuario = () => {
    
    const { contadorNotasPendientes} = useSelector(state => state.notas);
    const { contadorTareasPendientes} = useSelector(state => state.tareas);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(cargarContadorNotasUsuario());
    }, [dispatch]);

    useEffect(() => {
        dispatch(cargarContadorTareasSinHacer());
    }, [dispatch]);
    
  return (
    <Nav variant="tabs" as="ul">
        <Nav.Item as="li">
            <NavLink
                to={`/notasUsuario`}
                activeClassName="selected"
                className={isActive => "nav-link" + (isActive ? " active" : "") }
            >
               {`Notas  (${contadorNotasPendientes})` }
            </NavLink>
        </Nav.Item>
        <Nav.Item as="li">
            <NavLink
                to={`/tareasUsuario`}
                activeClassName="selected"
                className={isActive => "nav-link" + (isActive ? " active" : "") }
            >
               {`Tareas  (${contadorTareasPendientes})` }
            </NavLink>
        </Nav.Item>
    </Nav>
  )
}

export default MenuUsuario