import React from 'react'
import Container from 'react-bootstrap/Container';
import Header from '../components/corporativo/Header';

const AccesoDenegadoPage = () => {
  return (
    <>
      <Container fluid>
          <Header />
      </Container>
      <Container>
        <div className="row justify-content-center">
          <div className="padreHtml">
            <div
              id="html"
              className="col-auto"
              style={{ padding: "20px", fontSize: "14px", fontFamily: "arial" }}
            >
              <div className="abs-center">
                <div>
                  <h1>Acceso denegado</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </>
  )
}

export default AccesoDenegadoPage