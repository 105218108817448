import React from 'react'
import { useSelector } from 'react-redux';
import Button from 'react-bootstrap/Button'
import { Col, Row } from 'react-bootstrap';
import { jsPDF } from "jspdf";
import Container from 'react-bootstrap/Container';
import NotasSelectorPdf from './NotasSelectorPdf';
import { format } from 'date-fns';
import { history } from '../../store/store';

const BotonNotasPdf = () => {
    const { nombre } = useSelector( state => state.auth);
    const { notas } = useSelector( state => state.notas);
   
    const fecha= format(new Date(),"dd/MM/yyyy");

    const handleClickVolver =() =>{
        history.goBack();
    }

    const handlePdfNotaClick = async () => {
        
        const doc = new jsPDF({ orientation: 'p', unit: 'px', hotfixes: ["px_scaling"], format: "A4", putOnlyUsedFonts: true });
        const pdfjs = document.querySelector('#html');
        doc.html(pdfjs, {
            autoPaging:'text',
            margin:[20,0,20,0],
            callback: async (doc) => {
                doc.save(`notas${nombre}.pdf`);
            }
        });
    }

    return (
      <>
        <Container >
            <div className="row justify-content-center" >
                <div className="padreHtml">
                    <Row className="mt-3 ">
                        <Col lg="3" >
                            <Button
                                    variant="soliss" 
                                    size="lg"
                                    onClick={handlePdfNotaClick}
                            >
                                Exportar a pdf 
                            </Button>
                        </Col>
                        <Col lg="1" >
                            <Button
                                    variant="soliss" 
                                    size="lg"
                                    onClick={handleClickVolver}
                            >
                                Volver
                            </Button>
                        </Col>
                    </Row>
                    <div id="html" className="col-auto" style={{ padding: "20px", fontSize: "14px", fontFamily: "arial" }}>
                        <Row>
                            <Col lg="3">
                                <a href="https://soliss.es"><img src="https://www.soliss.es/file/2016/05/index_soliss_new-2.png" alt="Soliss" className="img-fluid" /></a>
                            
                            </Col>
                            <Col lg="6" className="align-content-center" style={{ textAlign:"center" }}>
                                <b>Listado de anotaciones</b>
                            
                            </Col>
                            <Col  className="text-end">
                            
                                {fecha}
                            </Col>
                        </Row>
                        
                        <hr />
                        {
                            notas.filter(n => n.show).map(nota => <NotasSelectorPdf key={nota.id} nota={nota} />)  
                        }  
                    </div>
                </div>
            </div>
        </Container>
      </>
    )

}
export default BotonNotasPdf