import { fetchConTokenYRefresh } from "./authServices";
import { format, parseISO } from "date-fns";
import { ambitos } from "../Data/ambitos";

export const fechaTareaToDate = (fecha) => {
  if (!fecha) return "";

  const componentesFecha = fecha.split("/");
  const dia = componentesFecha[0];
  const mes = componentesFecha[1] - 1;
  const anio = componentesFecha[2];

  return new Date(anio, mes, dia);
};

export const getTareas = async (ambito, id) => {
  const { data } = await fetchConTokenYRefresh(
    `gestornotas/tareas/${ambitos[ambito].url}/${id}`
  );
  return !data ? [] : mapTareas(data);
};

export const getTareasSinHacerUsuario = async (fecha) => {
  const { data } = await fetchConTokenYRefresh(
    `gestornotas/tareas?fechaConsulta=${format(fecha, "yyyy-MM-dd")}`
  );
  return !data ? [] : mapTareas(data);
};

export const getCountTareasPendientes = async (ambito, id) => {
  const { data } = await fetchConTokenYRefresh(
    `gestornotas/tareas/${ambitos[ambito].url}/${id}/count`
  );
  return !data ? 0 : data.count;
};

export const getCountTareasSinHacerUsuario = async () => {
  const { data } = await fetchConTokenYRefresh(`gestornotas/tareas/count`);
  return !data ? 0 : data.count;
};

export const createTarea = async (tarea) => {
  tarea.fechaInicio = format(tarea.fechaInicio, "yyyy-MM-dd");
  tarea.fechaCaducidad = format(tarea.fechaCaducidad, "yyyy-MM-dd");
  tarea.email =
    tarea.email && tarea.email.length > 0
      ? tarea.email.map((e) => e.value).join(";")
      : null;
  tarea.destinatarios = tarea.destinatarios.map((d) => d.value);
  tarea.tipoTareaId = 2;

  const {
    data: tareaCreated,
    ok,
    error,
  } = await fetchConTokenYRefresh(`gestornotas/tareas/`, tarea, "POST");

  return {
    tarea: mapTarea(tareaCreated),
    ok,
    error,
  };
};

export const getTiposTareas = async () => {
  const { data: tiposTareas } = await fetchConTokenYRefresh(
    "gestornotas/tiposTareas"
  );
  const tareasOptions = tiposTareas.map((tipoTarea) => ({
    value: tipoTarea.id,
    label: tipoTarea.nombre,
  }));

  return !tiposTareas ? [] : tareasOptions;
};

export const getEstadosTareas = async () => {
  const { data: tiposTareas } = await fetchConTokenYRefresh(
    "gestornotas/EstadosTareas"
  );
  const estadosTareasOptions = tiposTareas.map((estadoTarea) => ({
    value: estadoTarea.id,
    label: estadoTarea.nombre,
  }));

  return !estadosTareasOptions ? [] : estadosTareasOptions;
};

export const crearHistoricoPospuesta = async (
  tareaId,
  fechaCaducidad,
  observaciones
) => {
  const historicoEstado = {
    id: tareaId,
    estadoTareaId: 3,
    fechaCaducidadNueva: format(fechaCaducidad, "yyyy-MM-dd"),
    observaciones,
  };

  const { ok } = await fetchConTokenYRefresh(
    `gestornotas/TareaHistoricoEstados`,
    historicoEstado,
    "POST"
  );
  return ok;
};

export const crearHistoricoTerminada = async (tareaId) => {
  const historicoEstado = {
    id: tareaId,
    estadoTareaId: 2,
  };

  const { ok } = await fetchConTokenYRefresh(
    `gestornotas/TareaHistoricoEstados`,
    historicoEstado,
    "POST"
  );
  return ok;
};

export const crearHistoricoCancelada = async (tareaId, observaciones) => {
  const historicoEstado = {
    id: tareaId,
    estadoTareaId: 4,
    observaciones,
  };

  const { ok } = await fetchConTokenYRefresh(
    `gestornotas/TareaHistoricoEstados`,
    historicoEstado,
    "POST"
  );
  return ok;
};

export const putReasignarTarea = async (tareaId, destinatarios) => {
  const reasignarTareaCommand = {
    destinatarios: destinatarios.map((d) => d.value),
  };

  const { ok } = await fetchConTokenYRefresh(
    `gestornotas/tareas/${tareaId}`,
    reasignarTareaCommand,
    "PUT"
  );
  return ok;
};

export const postRehabilitarTarea = async (tareaId) => {
  const rehabilitarTareaCommand = {
    id: tareaId,
  };

  const { ok } = await fetchConTokenYRefresh(
    `gestornotas/TareaHistoricoEstados/rehabilitar`,
    rehabilitarTareaCommand,
    "POST"
  );
  return ok;
};

const mapTareas = (tareas) => {
  return tareas.map(mapTarea);
};

const mapTarea = (tarea) => {
  return {
    id: tarea.id,
    seccionId: tarea.mensaje.seccionId,
    grupo: { id: tarea.grupo.id, nombre: tarea.grupo.nombre },
    ambito: {
      id: tarea.mensaje.ambito.id,
      nombre: tarea.mensaje.ambito.nombre,
    },
    fecha: parseISO(tarea.mensaje.fechaCreacion),
    fechaInicio: tarea.fechaInicio ? parseISO(tarea.fechaInicio) : null,
    de: tarea.mensaje.usuarioRemitente?.nombre,
    tipo: tarea.mensaje.tipo,
    referenciaId: tarea.mensaje.referenciaId,
    texto: tarea.mensaje.texto,
    privado: tarea.mensaje.privado,
    tipoTareaId: tarea.tipoTarea?.id,
    tipoTarea: { id: tarea.tipoTarea.id, nombre: tarea.tipoTarea.nombre },
    fechaCaducidad: tarea.fechaCaducidad
      ? parseISO(tarea.fechaCaducidad)
      : null,
    estadoActual: tarea.ultimoEstado?.nombre,
    estadoActualId: tarea.ultimoEstado?.id,
    email: tarea.mensaje.email
      ?.split(";")
      .map((email) => ({ value: email, label: email })),
    grupoSelected: {
      value: tarea.grupo?.id,
      label: `${tarea.grupo?.nombre}`,
    },
    usuarioRemitente: {
      value: tarea.mensaje.usuarioRemitente.id,
      label: `${tarea.mensaje.usuarioRemitente.id} - ${tarea.mensaje.usuarioRemitente.nombre}`,
      extension: tarea.mensaje.usuarioRemitente.extension,
    },
    destinatarios:
      tarea.mensaje.destinatarios.length > 0
        ? tarea.mensaje.destinatarios.map((destinatario) => ({
            value: destinatario.id,
            label: `${destinatario.id} - ${destinatario.nombre}`,
          }))
        : [],
    url: tarea.url,
    show: true,
    observaciones: tarea.observaciones,
  };
};

export const aplicarFiltros = (tareas, filtros) => {
  const {
    tipos,
    futurasActualesTodas,
    privadasPublicas,
    grupos,
    ambitos,
    estadosTareas,
  } = filtros;

  let tareasFiltradas =
    futurasActualesTodas === 1
      ? tareas
      : futurasActualesTodas === 2
      ? tareas.filter((tarea) => tarea.fechaInicio <= new Date())
      : tareas.filter((tarea) => tarea.fechaInicio > new Date());

  tareasFiltradas =
    privadasPublicas === 1
      ? tareasFiltradas
      : privadasPublicas === 2
      ? tareasFiltradas.filter((tarea) => tarea.privado)
      : tareasFiltradas.filter((tarea) => !tarea.privado);

  if (tipos && tipos.length > 0) {
    const tiposTareas = tipos.map((s) => s.value);
    tareasFiltradas = tareasFiltradas.filter((tarea) =>
      tiposTareas.includes(tarea.tipoTarea.id)
    );
  }

  if (grupos && grupos.length > 0) {
    const opcionesGrupos = grupos.map((s) => s.value);
    tareasFiltradas = tareasFiltradas.filter((tarea) =>
      opcionesGrupos.includes(tarea.grupo.id)
    );
  }

  if (ambitos && ambitos.length > 0) {
    const ambitosOpciones = ambitos.map((s) => s.value);
    tareasFiltradas = tareasFiltradas.filter((tarea) =>
      ambitosOpciones.includes(tarea.ambito.id)
    );
  }
  if (estadosTareas.length > 0) {
    const estados = estadosTareas.map((s) => s.value);
    tareasFiltradas = tareasFiltradas.filter((tarea) =>
      estados.includes(tarea.estadoActualId)
    );
  }

  const tareasFiltradasIds = tareasFiltradas.map((t) => t.id);

  return tareas.map((t) => ({
    ...t,
    show: tareasFiltradasIds.includes(t.id),
  }));
};
