import React from 'react'
import Button from 'react-bootstrap/esm/Button';
import { useDispatch } from 'react-redux';
import { startRehabilitarTarea } from '../../middlewares/tareas';

const BotonRehabilitar = ({tareaId}) => {

    const dispatch = useDispatch();

    const handleClickRehabilitar = () =>  {
        dispatch(startRehabilitarTarea(tareaId));
    };

    return (
        <Button 
            variant="secondary"
            onClick={() => {handleClickRehabilitar()}}
        >
            <i className="fas fa-chevron-circle-up"></i> 
            <br/>
            Rehabilitar
        </Button>
    )
}

export default BotonRehabilitar