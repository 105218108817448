import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { establecerModoLectura } from '../../actions/filtrosActions';
import { opcionesModoLectura } from '../../options/opcionesModoLectura';
import RadioSoliss from '../ui/RadioSoliss';

const ModoLectura = () => {
  const dispatch = useDispatch();
    const { modoLectura } = useSelector(state => state.filtros);

    const handleModoLecturaClick = (modoLecturaOption) => {
        dispatch(establecerModoLectura(modoLecturaOption));
    }

    return (
        <RadioSoliss 
            name="modoLectura" 
            value={ modoLectura } 
            onChange={handleModoLecturaClick} 
            options={opcionesModoLectura} 
        />
    )
}

export default ModoLectura