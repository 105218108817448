import React from 'react'
import { Form } from 'react-bootstrap'
import ReactSelect from 'react-select'

const Grupo = ({ grupoSelected, gruposOptions, handleGrupoIdSelect, titulo }) => {
  return (
    <>
        <Form.Label  lg="2">{ titulo ?? "Grupo" }</Form.Label>
        <ReactSelect
            placeholder="Seleccione grupo" 
            options={gruposOptions}
            name="grupoId"
            value={grupoSelected}
            onChange={handleGrupoIdSelect}
        />
    </>
  )
}

export default Grupo