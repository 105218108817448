import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useParams } from 'react-router-dom';

import Header from '../components/corporativo/Header';
import { TareasMain } from '../components/tareas/TareasMain';
import BotonTareaNueva from '../components/tareas/BotonTareaNueva';
import TareasFiltros from '../components/tareas/TareasFiltros';
import Menu from '../components/ui/Menu';
import { ambitos } from '../Data/ambitos';
import { useEffect } from 'react';

const TareasPage = () => {

    const { ambito, id } = useParams();

    useEffect(() => {
        document.title = `Notas-${ambito.toUpperCase()}-${id}`;
    }, [ambito, id]);

    return (
        <Container fluid>
            <Header />

            <Menu />
            <Row>
                <Col sm={4} className="mt-2">
                    <h3>{ `${ ambitos[ambito].nombre } ${id}` }</h3>
                </Col>
                <Col>
                    <div className="d-grid gap-2">
                        <BotonTareaNueva buttonText="Tarea nueva" />
                    </div>
                </Col>
            </Row>
            
            <TareasFiltros />
            <TareasMain />
        </Container>
    )
}

export default TareasPage
