import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import {  filtrarLeidasNoLeidasTodas } from '../../actions/filtrosActions';
import { opcionesNotasLeidas } from '../../options/opcionesNotasLeidas';
import RadioSoliss from '../ui/RadioSoliss'

const LeidasToogle = () => {

    const dispatch = useDispatch();
    const { leidasNoleidasTodas } = useSelector(state => state.filtros);

    const handleLeidasClick = (tareasLeidasOption) => {
        dispatch(filtrarLeidasNoLeidasTodas(tareasLeidasOption));
    }

    return (
        <RadioSoliss 
            name="tareasLeidas" 
            value={ leidasNoleidasTodas } 
            onChange={handleLeidasClick} 
            options={opcionesNotasLeidas} 
        />
    )
}

export default LeidasToogle