import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import Select from "react-select";
import { filtrarPorSecciones } from '../../actions/filtrosActions';
import { startLoadingSeccionesOpciones } from '../../middlewares/filtros';

const Secciones = () => {

    const dispatch = useDispatch();
    const { secciones, seccionesOpciones } = useSelector(state => state.filtros);

    const handleAmbitoIdSelect = (seleccion) => {
        dispatch(filtrarPorSecciones(seleccion));
    }

    useEffect(() => {
        const abortController = new AbortController();
        dispatch(startLoadingSeccionesOpciones(abortController.signal))
        
        return () => {
            abortController.abort();
        }
    }, [ dispatch]);

    return (
        <Select
            className="basic-single"
            classNamePrefix="select"
            isClearable={true}
            isSearchable={true}
           // placeholder={tiposOpcionesPlaceholder}
            placeholder={"Seleccione Sección"}
            options={ seccionesOpciones }
            name={"seccionFilter"}
            value={ secciones }
            onChange={(seleccion) => handleAmbitoIdSelect(seleccion)} //esTarea ? handleTipoTareaIdSelect(seleccion) : handleTipoAnotacionIdSelect(seleccion)}
            isMulti={true}
            noOptionsMessage={({inputValue}) => "No se encontraron resultados"}
        />
    )
}

export default Secciones