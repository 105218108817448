import React from 'react';
import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { useSelector } from 'react-redux';
import Swal from 'sweetalert2';

import { postCall } from '../../services/callServices';

const Interlocutor = ({ destinatario }) => {
    const { extension } = useSelector(state => state.auth);
  const nombreDestinatario = destinatario.label.substring(9);

  const handleLlamarClick = async () => {
    await postCall({
      caller: extension, 
      called: destinatario.extension
    })
    Swal.fire({
      title: 'Llamando...', 
      text: `Le va a sonar el teléfono. Al descolgarlo, llamará automáticamente a ${nombreDestinatario}.`, 
      icon: 'info'
    });
  }

  return (
    <p>
      { nombreDestinatario }{' '}
      {
        destinatario.extension &&
          <OverlayTrigger
            placement={'top'}
            overlay={
              <Tooltip>
                Llamar a {nombreDestinatario}.
              </Tooltip>
            }
          >
            <Button 
              variant="secondary" 
              size="sm"
              onClick={handleLlamarClick}
            >
              { `${destinatario.extension}` } 
              <i className="fa fa-solid fa-phone"></i>
            </Button>
          </OverlayTrigger>
          
      }
    </p>
  )
}

export default Interlocutor