import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
import { TareasSelectoresMemorized } from "./TareasSelectores";
import TareasContenido from "../tareas/TareasContenido";
import { startLoadingTareas } from "../../middlewares/tareas";
import TareaNueva from "./TareaNueva";
import LoadingSpinner from "../shared/LoadingSpinner";
import { Tab } from "react-bootstrap";
import { aplicarFiltros } from "../../services/tareasServices";

export const TareasMain = () => {
  const { ambito, id } = useParams();
  const dispatch = useDispatch();
  const { tareas, cargando, mostrarFormulario, tareaSelected } = useSelector(
    (state) => state.tareas
  );
  const filtros = useSelector((state) => state.filtros);

  useEffect(() => {
    dispatch(startLoadingTareas(ambito, id));
  }, [dispatch, ambito, id]);

  const tareasFiltradas = useMemo(() => {
    return aplicarFiltros(tareas, filtros);
  }, [filtros, tareas]);

  return (
    <Tab.Container id="left-tabs-example sticky-top" activeKey={tareaSelected}>
      <Row className="row-height mt-3">
        <Col md={4} className="selector">
          {cargando ? (
            <LoadingSpinner />
          ) : (
            <TareasSelectoresMemorized
              tareas={tareasFiltradas}
              tareaSelected={tareaSelected}
            />
          )}
        </Col>
        <Col md={8} className="contenido">
          {cargando ? (
            <LoadingSpinner />
          ) : mostrarFormulario ? (
            <TareaNueva gruposOptions={filtros.gruposOpciones} />
          ) : (
            <TareasContenido />
          )}
        </Col>
      </Row>
    </Tab.Container>
  );
};
export default TareasMain;
