import { combineReducers } from "redux";
import { connectRouter } from 'connected-react-router'

import { authReducer } from "./authReducer";
import { filtrosReducer } from "./filtrosReducer";
import { notasReducer } from "./notasReducer";
import { tareasReducer } from "./tareasReducer";

export const rootReducer = (history) =>  combineReducers({
    router: connectRouter(history),
    auth: authReducer,
    filtros: filtrosReducer,
    notas: notasReducer,
    tareas: tareasReducer
})