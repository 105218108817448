import { useEffect } from "react";
import { useForm } from "./useForm";
import { notasTypes } from "../types/notasTypes";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { initialState } from "../reducers/notasReducer";

export const useNotas = () => {

    const { ambito, id } = useParams();
    const dispatch = useDispatch();
    const { notaActiva: notaActivaReducer, edicion } = useSelector( state => state.notas);
    const [ notaActiva, handleInputChange, , set ] = useForm({
        ...notaActivaReducer,
        tipo: ambito || notaActivaReducer.tipo,
        referenciaId: id || notaActivaReducer.referenciaId,
    });

    const limpiar = () => {
        set({
            ...initialState.notaActiva,
            grupoSelected: initialState.notaActiva.grupoSelected,
            tipo: ambito || initialState.notaActiva.tipo,
            referenciaId: id || initialState.notaActiva.referenciaId,
        });
    }

    useEffect(() => {
        dispatch({
            type: notasTypes.actualizarNotaActiva,
            payload: notaActiva
        });
    }, [dispatch, notaActiva])

    return {
        nota: notaActiva,
        edicion,
        handleInputChange,
        limpiar
    }
}