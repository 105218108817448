export const opcionesNotasLeidas = [
    {
        value:1,
        label: 'Todas'
    },
    {
        value:2,
        label: 'Leídas'
    },
    {
        value:3,
        label: 'No Leídas'
    }
]