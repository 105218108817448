import React from "react";
import format from "date-fns/format";
import DataTable from "react-data-table-component";

const FechaAvisoColumn = ({ fecha }) => {
  const tieneFecha = fecha !== "" && fecha !== null;

  return (
    <div data-tag="allowRowEvents">
      {tieneFecha && (
        <div>
          <small className="text-muted">{`${format(
            fecha,
            "dd/MM/yyyy"
          )}`}</small>
        </div>
      )}
    </div>
  );
};

const FechaEntraColumn = ({ fecha }) => {
  const tieneFecha = fecha !== "" && fecha !== null;

  return (
    <div data-tag="allowRowEvents">
      {tieneFecha && (
        <div>
          <small className="text-muted">{`${format(
            fecha,
            "dd/MM/yyyy HH:mm"
          )}`}</small>
        </div>
      )}
    </div>
  );
};

const Contenido = ({ texto }) => {
  return <div dangerouslySetInnerHTML={{ __html: texto }} />;
};

const NotasModoLectura = ({ notas }) => {
  const columns = [
    {
      name: "REMITENTE",
      selector: (row) => row.de,
      // sortable: true,
      center: false,
      grow: 1,
    },
    {
      name: "FECHA NOTA",
      selector: (row) => row.fecha,
      // sortable: true,
      center: false,
      grow: 0.45,
      cell: (row) => <FechaEntraColumn fecha={row.fecha} />,
    },
    {
      name: "FECHA AVISO",
      selector: (row) => row.fechaEnvio,
      // sortable: true,
      center: false,
      grow: 0.45,
      cell: (row) => <FechaAvisoColumn fecha={row.fechaEnvio} />,
    },
    {
      name: "NOTA",
      selector: (row) => <Contenido texto={row.texto} />,
      // sortable: true,
      center: false,
      grow: 4,
    },
  ];

  return (
    <div className="mt-3">
      <DataTable
        title="Notas"
        columns={columns}
        data={notas.filter((n) => n.show)}
        noDataComponent="No se encontraron registros" //or your component
      />
    </div>
  );
};

export default NotasModoLectura;
