import React, { useState } from "react";
import { useSelector } from "react-redux";
import Tab from "react-bootstrap/Tab";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import format from "date-fns/format";
import es from "date-fns/locale/es";
import LinkExpediente from "../shared/LinkExpediente";
import RemitenteDetinatarios from "../shared/RemitenteDetinatarios";
import { Card } from "react-bootstrap";
import PosponerTarea from "./PosponerTarea";
import BotonReasignar from "./BotonReasignar";
import BotonPosponer from "./BotonPosponer";
import BotonTerminar from "./BotonTerminar";
import BotonCancelar from "./BotonCancelar";
import BotonTareaNuevaDesdeOtra from "./BotonTareaNuevaDesdeOtra";
import ReasignarTarea from "./ReasignarTarea";
import BotonRehabilitar from "./BotonRehabilitar";
import CancelarTarea from "./CancelarTarea";

const TareasContenido = () => {
  const { usuarioId } = useSelector((state) => state.auth);
  const { tareas } = useSelector((state) => state.tareas);
  const [mostrarPosponer, setMostrarPosponer] = useState(false);
  const [mostrarCancelar, setMostrarCancelar] = useState(false);
  const [mostrarReasignar, setMostrarReasignar] = useState(false);

  return (
    <Tab.Content>
      {tareas.map((tarea) => {
        const fechaDetalle = format(tarea.fechaInicio, "EEEE dd/MM/yyyy", {
          locale: es,
        });
        const fechaCaduca = tarea.fechaCaducidad
          ? format(tarea.fechaCaducidad, "EEEE dd/MM/yyyy", { locale: es })
          : null;

        return (
          <Tab.Pane key={tarea.id} eventKey={tarea.id}>
            <Card>
              <Card.Header>
                {`${tarea.ambito.nombre}: ${tarea.referenciaId}`}
              </Card.Header>
              <Card.Body>
                <RemitenteDetinatarios
                  de={tarea.usuarioRemitente}
                  para={tarea.destinatarios}
                  email={tarea.email}
                />
                <Row>
                  <Col xs={4}>
                    <div className="col-sm-10">
                      Fecha de inicio:{" "}
                      <small className="text-muted">{fechaDetalle}</small>
                    </div>
                    {fechaCaduca && (
                      <>
                        {" "}
                        <div className="col-sm-10">
                          Fecha de caducidad:{" "}
                          <small className="text-muted"> {fechaCaduca} </small>{" "}
                        </div>{" "}
                      </>
                    )}
                  </Col>
                  {tarea.estadoActual === "PENDIENTE" ||
                  tarea.estadoActual === "POSPUESTA" ? (
                    <>
                      <Col xs={1}>
                        <BotonPosponer
                          setMostrarCalendario={setMostrarPosponer}
                        />
                      </Col>
                      {(usuarioId === "010311" || usuarioId === "030112") && (
                        <Col xs={1}>
                          <BotonReasignar
                            setMostrarReasignar={setMostrarReasignar}
                          />
                        </Col>
                      )}
                      <Col xs={1}>
                        <BotonTerminar tareaId={tarea.id} />
                      </Col>
                      <Col xs={1}>
                        <BotonCancelar
                          tareaId={tarea.id}
                          setMostrarCancelar={setMostrarCancelar}
                        />
                      </Col>
                    </>
                  ) : (
                    <Col xs={5}>
                      <BotonTareaNuevaDesdeOtra tareaId={tarea.id} />
                    </Col>
                  )}
                  {tarea.estadoActual === "CANCELADA" && (
                    <BotonRehabilitar tareaId={tarea.id} />
                  )}
                </Row>
                {mostrarPosponer && (
                  <PosponerTarea
                    tarea={tarea}
                    setMostrarPosponer={setMostrarPosponer}
                  />
                )}
                {mostrarCancelar && (
                  <CancelarTarea
                    tareaId={tarea.id}
                    setMostrarCancelar={setMostrarCancelar}
                  />
                )}
                {mostrarReasignar && (
                  <ReasignarTarea
                    tareaId={tarea.id}
                    tareaDestinatarios={tarea.destinatarios}
                  />
                )}
                {tarea.url && (
                  <LinkExpediente
                    url={tarea.url}
                    ambito={tarea.ambito.nombre.toLowerCase()}
                  />
                )}
                <hr />
                <div dangerouslySetInnerHTML={{ __html: tarea.texto }} />
                {tarea.observaciones && (
                  <p>
                    <b>Observaciones:</b> {tarea.observaciones}
                  </p>
                )}
              </Card.Body>
            </Card>
          </Tab.Pane>
        );
      })}
    </Tab.Content>
  );
};

export default TareasContenido;
