import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import Header from "../components/corporativo/Header";
import MenuUsuario from "../components/ui/MenuUsuario";
import { useSelector } from "react-redux";
import NotasMainUsuario from "../components/notas/NotasMainUsuario";
import NotasFiltrosUsuario from "../components/notas/NotasFiltrosUsuario";
import BotonNotasOficina from "../components/notas/BotonNotasOficina";
import BotonNavegarNotasPdf from "../components/notas/BotonNavegarNotasPdf";
import { useEffect } from "react";
import Notificacion from "../components/shared/Notificacion";
import { SignalRNotasProvider } from "../contexts/SignalRContext";

const NotasUsuarioPage = () => {
  const { usuarioId, nombre } = useSelector((state) => state.auth);

  useEffect(() => {
    document.title = `Notas-${usuarioId}-${nombre}`;
  }, [usuarioId, nombre]);

  return (
    <SignalRNotasProvider>
      <Container fluid>
        <Header />

        <MenuUsuario />
        <Row>
          <Col sm={4} className="mt-2">
            <BotonNotasOficina />
            <BotonNavegarNotasPdf />
          </Col>
          <Col sm={8}>
            <div className="d-grid gap-2">
              <NotasFiltrosUsuario />
            </div>
          </Col>
        </Row>

        <NotasMainUsuario />
        <Notificacion />
      </Container>
    </SignalRNotasProvider>
  );
};

export default NotasUsuarioPage;
