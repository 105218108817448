import React from 'react'
import RadioSolissItem from './RadioSolissItem'

const RadioSoliss = ({ name, value, onChange, options }) => {
    
    const optionsRadio = options || [ { label: 'Sí', value: true }, { label: 'No', value: false } ]

    return (
        <div className="btn-group" role="group">
            {
                optionsRadio.map((opt, idx) => (
                    <RadioSolissItem key={idx} name={name} value={value} onChange={onChange} option={opt} idx={idx} />
                ))
            }
        </div>
    )
}

export default RadioSoliss
