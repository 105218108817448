export const opcionesNotasFuturas = [
    {
        value:1,
        label: 'Todas'
    },
    {
        value:2,
        label: 'Actuales'
    },
    {
        value:3,
        label: 'Futuras'
    }
]