import React, { useContext, useEffect } from "react";
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";

import NotasContenido from "./NotasContenido";
import { NotasSelectoresMemorized } from "./NotasSelectores";
import NotaNueva from "./NotaNueva";
import { useDispatch, useSelector } from "react-redux";
import {
  startLoadingNotasOficina,
  startLoadingNotasUsuario,
} from "../../middlewares/notas";
import { SignalRContext } from "../../contexts/SignalRContext";
import { eliminarNota, filtrarNotas } from "../../actions/notasActions";
import { Tab } from "react-bootstrap";
import LoadingSpinner from "../shared/LoadingSpinner";
import NotasModoLectura from "./NotasModoLectura";

export const NotasMainUsuario = () => {
  const dispatch = useDispatch();
  const { notas, cargando, mostrarFormulario, notaSelected } = useSelector(
    (state) => state.notas
  );
  const filtros = useSelector((state) => state.filtros);
  const { fecha, notasOficina } = filtros;
  const { connection } = useContext(SignalRContext);

  useEffect(() => {
    const abortController = new AbortController();
    console.log("useEffect");
    connection.on("NotaDirigidaATiCreada", () => {
      if (notasOficina) {
        dispatch(startLoadingNotasOficina(fecha, abortController.signal));
      } else {
        dispatch(startLoadingNotasUsuario(fecha, abortController.signal));
      }
    });

    return () => {
      abortController.abort();
      connection.off("NotaDirigidaATiCreada");
    };
  }, [connection, dispatch, fecha, notasOficina]);

  useEffect(() => {
    connection.on("MensajeEliminado", (id) => {
      dispatch(eliminarNota(id));
    });

    return () => {
      connection.off("MensajeEliminado");
    };
  }, [connection, dispatch]);

  useEffect(() => {
    const abortController = new AbortController();
    if (notasOficina) {
      dispatch(startLoadingNotasOficina(fecha, abortController.signal));
    } else {
      dispatch(startLoadingNotasUsuario(fecha, abortController.signal));
    }

    return () => {
      abortController.abort();
    };
  }, [dispatch, fecha, notasOficina]);

  useEffect(() => {
    if (!cargando) {
      dispatch(filtrarNotas(filtros));
    }
  }, [dispatch, filtros, cargando]);

  return filtros.modoLectura ? (
    <NotasModoLectura notas={notas} />
  ) : (
    <Tab.Container id="left-tabs-example sticky-top" activeKey={notaSelected}>
      <Row className="row-height mt-3">
        <Col md={4} className="selector">
          {cargando ? (
            <LoadingSpinner />
          ) : (
            <NotasSelectoresMemorized
              notas={notas}
              notaSelected={notaSelected}
            />
          )}
        </Col>
        <Col md={8} className="contenido">
          {cargando ? (
            <LoadingSpinner />
          ) : mostrarFormulario ? (
            <NotaNueva gruposOptions={filtros.gruposOpciones} />
          ) : (
            <NotasContenido notasUsuario={true} />
          )}
        </Col>
      </Row>
    </Tab.Container>
  );
};
export default NotasMainUsuario;
