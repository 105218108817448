import React, { useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useParams } from "react-router-dom";

import Header from "../components/corporativo/Header";
import { NotasMain } from "../components/notas/NotasMain";
import BotonNotaNueva from "../components/notas/BotonNotaNueva";
import NotasFiltros from "../components/notas/NotasFiltros";
import Menu from "../components/ui/Menu";
import { ambitos } from "../Data/ambitos";
import Notificacion from "../components/shared/Notificacion";
import { SignalRNotasProvider } from "../contexts/SignalRContext";

const NotasPage = () => {
  const { ambito, id } = useParams();

  useEffect(() => {
    document.title = `Notas-${ambito.toUpperCase()}-${id}`;
  }, [ambito, id]);

  return (
    <SignalRNotasProvider>
      <Container fluid>
        <Header />

        <Menu />
        <Row>
          <Col sm={4} className="mt-2">
            <h3>{`${ambitos[ambito].nombre} ${id}`}</h3>
          </Col>
          <Col>
            <div className="d-grid gap-2">
              <BotonNotaNueva />
            </div>
          </Col>
        </Row>

        <NotasFiltros />
        <NotasMain />
        <Notificacion />
      </Container>
    </SignalRNotasProvider>
  );
};

export default NotasPage;
