import React from 'react'
import OverlayTrigger from 'react-bootstrap/esm/OverlayTrigger'
import Tooltip from 'react-bootstrap/esm/Tooltip'

const Privada = ({ nota }) => {
  return (
    <OverlayTrigger
        key={nota.id}
        placement="top"
        overlay={
            <Tooltip id={`tooltip-${nota.id}`}>
                Nota privada
            </Tooltip>
        }
    >
        <span><i className="fas fa-lock"></i></span>
    </OverlayTrigger>
  )
}

export default Privada