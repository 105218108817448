import React, { useContext, useEffect } from 'react'
import { SignalRContext } from '../../contexts/SignalRContext';
import Col from 'react-bootstrap/esm/Col'
import Row from 'react-bootstrap/esm/Row'
import { useParams } from 'react-router-dom'
import * as signalR from '@microsoft/signalr';
import NotasContenido from './NotasContenido'
import { NotasSelectoresMemorized } from './NotasSelectores'
import NotaNueva from './NotaNueva';
import { useDispatch } from 'react-redux'
import { startLoadingNotas } from '../../middlewares/notas'
import { useSelector } from 'react-redux'
import { Tab } from 'react-bootstrap';
import { eliminarNota, filtrarNotas } from '../../actions/notasActions';
import LoadingSpinner from '../shared/LoadingSpinner';
import NotasModoLectura from './NotasModoLectura';

export const NotasMain = () => {

    const dispatch = useDispatch();
    const { ambito, id } = useParams();
    const { notas, cargando, mostrarFormulario, notaSelected } = useSelector( state => state.notas);
    const filtros = useSelector( state => state.filtros);
    const { connection } = useContext(SignalRContext);

    
    useEffect(() => {
        dispatch(startLoadingNotas(ambito, id))
    }, [dispatch, ambito, id])

    useEffect(() => {
        if (connection.state === signalR.HubConnectionState.Connected) {
            connection.send('JoinGroup', `${ambito}-${id}`)
        }

        return () => {
            if (connection.state === signalR.HubConnectionState.Connected) {
                connection.send('RemoveFromGroup', `${ambito}-${id}`)
            }
        }
    }, [connection, connection.state, ambito, id]);

    useEffect(() => {
        connection.on('NotaCreada', () => {
            dispatch(startLoadingNotas(ambito, id))
        });

        return () => {
            connection.off('NotaCreada');
        }
    }, [connection, ambito, id, dispatch]);

    useEffect(() => {
        connection.on('MensajeEliminado', (id) => {
          dispatch(eliminarNota(id));
        });

        return () => {
            connection.off('MensajeEliminado');
        }
    }, [connection, dispatch]);

    useEffect(() => {
        if (!cargando) {
          dispatch(filtrarNotas(filtros));
        }
      }, [dispatch, filtros, cargando])

    return (
        filtros.modoLectura ?
            <NotasModoLectura notas={notas} /> :
            <Tab.Container id="left-tabs-example sticky-top" activeKey={notaSelected}>
                <Row className="row-height mt-3">
                    <Col md={4} className="selector">
                        {
                            cargando ?
                                <LoadingSpinner /> :
                                <NotasSelectoresMemorized notas={notas} notaSelected={notaSelected} />
                        }
                    </Col>
                    <Col md={8} className="contenido">
                        {
                            cargando ?
                                <LoadingSpinner /> :
                                mostrarFormulario ?
                                    <NotaNueva gruposOptions={filtros.gruposOpciones} /> :
                                    <NotasContenido notasUsuario={false} /> 
                        }
                    </Col>
                </Row>
            </Tab.Container>
    )
}
export default NotasMain
