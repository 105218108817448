import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Swal from "sweetalert2";
import DatePicker, { registerLocale } from "react-datepicker";
import { startOfTomorrow } from "date-fns";
import es from "date-fns/locale/es";
import "react-datepicker/dist/react-datepicker.css";

import RadioSoliss from "../ui/RadioSoliss";
import { opcionesPrivada } from "../../options/opcionesPrivada";
import { useDispatch, useSelector } from "react-redux";
import { startCrearNota, startUpdateNota } from "../../middlewares/notas";
import { useNotas } from "../../hooks/useNotas";
import EnvioEmail from "../shared/EnvioEmail";
import Destinatarios from "../shared/Destinatarios";
import Grupo from "../shared/Grupo";
import MensajeTexto from "../shared/MensajeTexto";
import BotonesNueva from "../shared/BotonesNueva";
import {
  getMatriculaContraria,
  validarMatricula,
} from "../../services/matriculaContrariaServices";

registerLocale("es", es);
const NotaNueva = ({ gruposOptions }) => {
  const { usuarioId, roles } = useSelector((state) => state.auth);
  const { nota, edicion, handleInputChange, limpiar } = useNotas();
  const dispatch = useDispatch();
  const [gruposSelected, setGrupoSelectedOption] = useState(nota.grupoSelected);

  const handleNotaInputChange = useCallback(
    (name, value) => {
      handleInputChange({ target: { name, value } });
    },
    [handleInputChange]
  );

  const handleDestinatarioSelect = async (selecciones) => {
    handleNotaInputChange("destinatarios", selecciones);

    if (!nota.fechaEnvio && selecciones.length > 0) {
      handleFechaEnvioChange(new Date());
    } else if (nota.fechaEnvio && selecciones.length === 0) {
      handleFechaEnvioChange("");
    }

    if (
      nota.referenciaId.substring(0, 2) === "01" &&
      selecciones.some((d) => d.value === "010701")
    ) {
      const matricula = await getMatriculaContraria(nota.referenciaId);
      handleNotaInputChange("texto", `801-${matricula}`);
    }
  };

  const handleGrupoIdSelect = (seleccion) => {
    setGrupoSelectedOption(seleccion);
    handleNotaInputChange("grupoId", seleccion.value);
  };

  const handleEmailsChange = useCallback(
    (emailsSelected) => {
      handleNotaInputChange("email", emailsSelected);
    },
    [handleNotaInputChange]
  );

  const handleCheckPrivada = (privada) => {
    handleNotaInputChange("privado", privada);
  };

  const handleFechaEnvioChange = (fecha) => {
    handleNotaInputChange("fechaEnvio", fecha);
  };

  const handleSubmit = async (ev) => {
    ev.preventDefault();

    if (nota.texto.trim().length === 0) {
      return Swal.fire("Error", "Debe escribir una anotación.", "error");
    }

    if (
      (!nota.fechaEnvio || !nota.fechaEnvio instanceof Date) &&
      nota.destinatarios.length > 0
    ) {
      return Swal.fire(
        "Error",
        "Debe seleccionar una fecha de envío válida.",
        "error"
      );
    }

    if (nota.destinatarios.some((d) => d.value === "010701")) {
      if (!nota.texto.startsWith("801-") && !nota.texto.startsWith("803-")) {
        return Swal.fire(
          "Error",
          "Los envíos FIVA deben comenzar con 801- o con 803-.",
          "error"
        );
      }

      const matricula = nota.texto.substring(4);

      if (!matricula || !(await validarMatricula(matricula))) {
        return Swal.fire("Error", "Matrícula incorrecta.", "error");
      }
    }

    if (
      nota.fechaEnvio >= startOfTomorrow() &&
      (!nota.destinatarios || nota.destinatarios.length < 1)
    ) {
      return Swal.fire(
        "Error",
        "Es necesario indicar destinatario en notas futuras.",
        "error"
      );
    }

    if (edicion) {
      dispatch(startUpdateNota(nota));
    } else {
      dispatch(startCrearNota(nota));
    }
  };

  return (
    <Form autoComplete="off" onSubmit={handleSubmit}>
      <Destinatarios
        destinatariosSelected={nota.destinatarios}
        handleDestinatarioSelect={handleDestinatarioSelect}
      />
      {nota.destinatarios.length > 0 && (
        <Row className="mt-3">
          <Col>
            <EnvioEmail
              emailsSelected={nota.email}
              destinatarios={nota.destinatarios}
              handleEmailsChange={handleEmailsChange}
            />
          </Col>
        </Row>
      )}

      <Row className="mt-3">
        {(!usuarioId.startsWith("02") ||
          usuarioId === "022403" ||
          roles.some((r) => r === "Responsable Tecnico")) && (
          <Col lg="2">
            <RadioSoliss
              name="privado"
              value={nota.privado}
              onChange={handleCheckPrivada}
              options={opcionesPrivada}
            />
          </Col>
        )}

        <Col lg="2">
          <Form.Label lg="2">Avisar en fecha</Form.Label>
          <DatePicker
            className="form-control"
            locale="es"
            dateFormat="dd/MM/yyyy"
            selected={nota.fechaEnvio}
            minDate={new Date()}
            placeholderText={"Fecha de envío"}
            name="fechaEnvio"
            value={nota.fechaEnvio}
            onChange={handleFechaEnvioChange}
          />
        </Col>
        <Col lg="4">
          <Grupo
            gruposOptions={gruposOptions}
            grupoSelected={gruposSelected}
            handleGrupoIdSelect={handleGrupoIdSelect}
            titulo="Tipo de nota"
          />
        </Col>
      </Row>

      <Form.Group className="mt-3" controlId="exampleForm.ControlTextarea1">
        <MensajeTexto texto={nota.texto} onChange={handleInputChange} />
      </Form.Group>

      <BotonesNueva onClickLimpiar={limpiar} />
    </Form>
  );
};

NotaNueva.propTypes = {
  gruposOptions: PropTypes.array.isRequired,
};

export default NotaNueva;
