import { authTypes } from "../types/authTypes";

export const login = (token) => ({
    type: authTypes.login,
    payload: token
});

export const loginFail = () => ({
    type: authTypes.loginFail
});

export const logOut = () => ({
    type: authTypes.logout
});

export const setSustituciones = (sustituciones) => ({
    type: authTypes.setSustitucionesNotas,
    payload: sustituciones
});