import { fetchConTokenYRefresh } from "./authServices";

export const getUsuarios = async () => {
    //const { data } = await fetchConTokenYRefresh(`common/notas/siniestro/${siniestroId}?leidas=false`);
    const { data } = await fetchConTokenYRefresh(`gestornotas/usuarios`);
    return mapUsuarios(data);
}

// destinatarios: Destinatarios de la tarea o la nota
// options: Todos los usuarios del desplegable
export const getEmailsByUsuarioId = async (destinatarios) => {
    const userIds = destinatarios.map(destinatario => destinatario.value);
    const userIdsQueryString = userIds.map(u => [ "usuarioId", u ])
    const params = new URLSearchParams(userIdsQueryString);
    const { data } = await fetchConTokenYRefresh(`gestornotas/usuarios/emails?${params.toString()}`);

    if(!data || !data.emails || data.emails.length === 0) return [];

    return data.emails;
}

const mapUsuarios = (usuarios) => {
    return usuarios.map(usuario => ({
        value: usuario.id,
        label: `${usuario.id} - ${usuario.nombre}`,
        email: usuario.email
    }));
}