import { fetchConTokenYRefresh } from "./authServices";

export const postCall = async ({ caller, called }) => {

    const llamada = {
        extension: caller,
        movil: called
    }

    const { ok } = await fetchConTokenYRefresh("call", llamada, 'POST');
    
    return ok;
}