import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import Select from "react-select";
import { filtrarPorAmbitos } from '../../actions/filtrosActions';
import { startLoadingAmbitosOpcionesNotas } from '../../middlewares/filtros';

const Ambitos = () => {

    const dispatch = useDispatch();
    const { ambitos, ambitosOpciones } = useSelector(state => state.filtros);

    const handleAmbitoIdSelect = (seleccion) => {
        dispatch(filtrarPorAmbitos(seleccion));
    }

    useEffect(() => {
        const abortController = new AbortController();
        dispatch(startLoadingAmbitosOpcionesNotas(abortController.signal))
        
        return () => {
            abortController.abort();
        }
    }, [ dispatch]);

    return (
        <Select
            className="basic-single"
            classNamePrefix="select"
            isClearable={true}
            isSearchable={true}
           // placeholder={tiposOpcionesPlaceholder}
            placeholder={"Seleccione Ambito"}
            options={ ambitosOpciones }
            name={"ambitoFilter"}
            value={ ambitos }
            onChange={(seleccion) => handleAmbitoIdSelect(seleccion)} //esTarea ? handleTipoTareaIdSelect(seleccion) : handleTipoAnotacionIdSelect(seleccion)}
            isMulti={true}
            noOptionsMessage={({inputValue}) => "No se encontraron resultados"}
        />
    )
}

export default Ambitos