import React, { useEffect } from 'react'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ActualesFuturas from '../filtros/ActualesFuturas';
import PrivadasPublicas from '../filtros/PrivadasPublicas';
import LeidasToogle from '../filtros/LeidasToogle';
import Grupos from '../filtros/Grupos';
import { useDispatch, useSelector } from 'react-redux';
import { marcarNotaSelected, ocultarFormularioNueva } from '../../actions/notasActions';
import { filtrarFuturasActualesTodas, filtrarLeidasNoLeidasTodas, filtrarPrivadasPublicas } from '../../actions/filtrosActions';
import ModoLectura from '../filtros/ModoLectura';

const NotasFiltros = () => {

    const dispatch = useDispatch();
    const filtros = useSelector(state => state.filtros)

    useEffect(() => {
        dispatch(filtrarFuturasActualesTodas(1));
        dispatch(filtrarLeidasNoLeidasTodas(1));
        dispatch(filtrarPrivadasPublicas(1));
      }, [dispatch])

    useEffect(() => {
        dispatch(ocultarFormularioNueva())
        dispatch(marcarNotaSelected(null));
    }, [dispatch, filtros])

    return (
        <Row>
            <Col sm={4}>
                <Row className='justify-content-md-center'>
                    <Col className="mt-3">
                        <ActualesFuturas />
                    </Col>
                    <Col className="mt-3">
                        <PrivadasPublicas />
                    </Col>
                    <Col className="mt-3">
                        <ModoLectura />
                    </Col>
                   
                </Row>
                <Row className='justify-content-md-center'>
                   
                    <Col className="mt-3">
                        <LeidasToogle />
                    </Col>
                    <Col  className="mt-2">
                        <Grupos />
                    </Col>
                </Row>
            </Col>
        </Row>
  )
}

export default NotasFiltros