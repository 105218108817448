import React from 'react'
import Interlocutor from './Interlocutor'

const RemitenteDetinatarios = ({ de, para, email }) => {
    
  return (
    <dl className="row">
        <dt className="col-sm-1">De:</dt>
        <dd className="col-sm-11"><Interlocutor destinatario={de} /></dd>
        
        {
            (para && para.length) > 0 &&
                <>
                    <dt className="col-sm-1">Para</dt>
                    <dd className="col-sm-5">
                        {
                            para.map(destinatario => <Interlocutor key={destinatario.value} destinatario={destinatario} />)
                        }
                    </dd>
                    {
                        (email) &&
                            <dd className="col-sm-6">
                                <dl className='row'>
                                    <dt className="col-sm-2">Email:</dt>
                                    <dd className="col-sm-10">
                                        {
                                            email.map(e => e.label).join(',')
                                        }
                                    </dd>
                                </dl>
                            </dd>
                    }
                </>
        }
    </dl>
  )
}

export default RemitenteDetinatarios