import React, { useEffect } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import PrivadasPublicas from '../filtros/PrivadasPublicas';
import TiposTareas from '../filtros/TiposTareas';
import Fecha from '../filtros/Fecha';
import Ambitos from '../filtros/Ambitos';
import Grupos from '../filtros/Grupos';
import EstadosTareas from '../filtros/EstadosTareas';
import { useDispatch } from 'react-redux';
import { filtrarPrivadasPublicas } from '../../actions/filtrosActions';


const TareasFiltrosUsuario = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(filtrarPrivadasPublicas(1));
    }, [dispatch])

    return (
        <>
            <Row className='mt-3'>
                <Col sm={2}>
                    <Fecha />
                </Col>
                <Col sm={4}>
                    <Ambitos />
                </Col>
                <Col sm={4}>
                    <TiposTareas />
                </Col>
                <Col sm={2}>
                    <PrivadasPublicas />
                </Col>
            </Row>
            <Row className='mt-3'>
                <Col sm={4}>
                    <Grupos />
                </Col>
                <Col sm={8}>
                    <EstadosTareas />
                </Col>
            </Row>
            <hr />
        </>
    )
}

export default TareasFiltrosUsuario