import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import Select from "react-select";
import { filtrarPorGrupos } from '../../actions/filtrosActions';
import { startLoadingGruposOpciones } from '../../middlewares/filtros';

const Grupos = () => {

    const dispatch = useDispatch();
    const {  grupos, gruposOpciones } = useSelector(state => state.filtros);

    const handleTipoIdSelect = (seleccion) => {
        dispatch(filtrarPorGrupos(seleccion));
    }

    useEffect(() => {
       
            dispatch(startLoadingGruposOpciones())
        
    }, [ dispatch]);

    return (
        <Select
            className="basic-single"
            classNamePrefix="select"
            isClearable={true}
            isSearchable={true}
            placeholder={"Grupos"}
            options={ gruposOpciones }
            name={"tipoFilter"}
            value={ grupos }
            onChange={(seleccion) => handleTipoIdSelect(seleccion)}
            isMulti={true}
            noOptionsMessage={({inputValue}) => "No se encontraron resultados"}
        />
    )
}

export default Grupos