import { fetchConTokenYRefresh } from "./authServices";

export const getMatriculaContraria = async (siniestroId) => {
    const { data } = await fetchConTokenYRefresh(`gestornotas/matriculascontrarias/${siniestroId}`);

    if(!data) return '';

    const { matricula } = data;
    return matricula;
}

export const validarMatricula = async (matricula) => {
    const { data } = await fetchConTokenYRefresh(`gestornotas/validarmatriculas/${matricula}`);
    const { matriculaValida } = data;
    return matriculaValida;
}