export const tareasTypes = {
  mostrarFormulario: "TAREAS_MOSTRAR_FORMULARIO",
  ocultarFormulario: "TAREAS_OCULTAR_FORMULARIO",
  mostrarFormularioNuevaAPartirDeOtra:
    "TAREAS_MOSTRAR_FORMULARIO_NUEVA_A_PARTIR_DE_OTRA",
  cargarTareas: "TAREAS_CARGAR",
  crearEstadoTarea: "TAREAS_CREAR_ESTADO",
  nuevaTarea: "TAREAS_NUEVA",
  nuevasTareas: "TAREAS_NUEVAS",
  actualizarTareaActiva: "TAREAS_ACTUALIZAR_ACTIVA",
  marcarCancelada: "TAREAS_CANCELAR",
  marcarTerminada: "TAREAS_TERMINAR",
  marcarPospuesta: "TAREAS_POSPONER",
  selectedTarea: "TAREAS_SELECCIONAR",
  seleccionarTareaSiguiente: "TAREAS_SELECCIONAR_SIGUIENTE",
  seleccionarTareaAnterior: "TAREAS_SELECCIONAR_ANTERIOR",
  setTareaActiva: "TAREAS_ACTIVA",
  getContador: "TAREAS_CONTADOR",
  actualizarContador: "TAREAS_ACTUALIZAR",
  reasignarTarea: "TAREAS_REASIGNAR",
  rehabilitarTarea: "TAREAS_REHABILITAR",
};
