import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { filtrarFuturasActualesTodas } from '../../actions/filtrosActions';
import { opcionesNotasFuturas } from '../../options/opcionesNotasFuturas';
import RadioSoliss from '../ui/RadioSoliss'

const ActualesFuturas = () => {

    const dispatch = useDispatch();
    const { futurasActualesTodas } = useSelector(state => state.filtros);

    const handleNotasFuturasClick = (notasFuturasOption) => {
        dispatch(filtrarFuturasActualesTodas(notasFuturasOption));
    }

    return (
        <RadioSoliss 
            name="notasFuturas" 
            value={ futurasActualesTodas } 
            onChange={handleNotasFuturasClick} 
            options={opcionesNotasFuturas} 
        />
    )
}

export default ActualesFuturas