import React, { useEffect } from 'react'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import PrivadasPublicas from '../filtros/PrivadasPublicas';

import LeidasToogle from '../filtros/LeidasToogle';
import Fecha from '../filtros/Fecha';
import Grupos from '../filtros/Grupos';
import Ambitos from '../filtros/Ambitos';
import { useDispatch, useSelector } from 'react-redux';
import { filtrarFuturasActualesTodas, filtrarLeidasNoLeidasTodas, filtrarPrivadasPublicas } from '../../actions/filtrosActions';
import { marcarNotaSelected, ocultarFormularioNueva } from '../../actions/notasActions';
import Secciones from '../filtros/Secciones';

const NotasFiltrosUsuario = () => {

    const dispatch = useDispatch();
    const filtros = useSelector(state => state.filtros)

    useEffect(() => {
      dispatch(filtrarFuturasActualesTodas(1));
      dispatch(filtrarPrivadasPublicas(1));
      dispatch(filtrarLeidasNoLeidasTodas(3));
    }, [dispatch])

    useEffect(() => {
        dispatch(ocultarFormularioNueva())
        dispatch(marcarNotaSelected(null));
    }, [dispatch, filtros])

    return (
        <>
            <Row>
                <Col>
                    <Row className="mt-3">
                        <Col >
                            <Fecha />
                        </Col>
                        <Col  >
                            <Secciones />
                        </Col>
                        <Col  >
                            <Ambitos />
                        </Col>
                        <Col  >
                            <Grupos />
                        </Col>
                    </Row>
                    <Row>
                        <Col className="mt-3">
                            <PrivadasPublicas />
                        </Col>
                        <Col className="mt-3">
                            <LeidasToogle />
                        </Col>
                    </Row>
                </Col>
            </Row>
            <hr />
        </>
  )
}

export default NotasFiltrosUsuario