import { setSustituciones } from "../actions/loginActions";
import { getSustituciones } from "../services/notasServices";
import { push } from 'connected-react-router';
import { login, loginFail } from "../actions/loginActions";
import { refreshToken } from "../services/authServices";

export const setSustitucionesNotas = (usuarioId) => {
    return async (dispatch) => {
        try {
            const sustituciones = await getSustituciones(usuarioId);
            dispatch(setSustituciones(sustituciones));
        } catch (error) {
            dispatch(setSustituciones([]));
            
        }
    };  
};

export const startRefreshToken = (redirect = null) => {
    return async (dispatch) => {
        try{
            const token = await refreshToken();
            if (token) {
                dispatch(login(token));

                if (redirect) {
                    dispatch(push(redirect));
                }
            } else {
                dispatch(loginFail());
                dispatch(push("/accesodenegado"));
            }
        }
        catch
        {
            dispatch(loginFail());
            dispatch(push("/accesodenegado"))
        }
    }
}
