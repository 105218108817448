import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import {
  filtrarPorEstadosTareas,
  startLoadingEstadosTareasOpciones,
} from "../../actions/filtrosActions";

const EstadosTareas = () => {
  const dispatch = useDispatch();
  const { estadosTareas, estadosTareasOpciones } = useSelector(
    (state) => state.filtros
  );

  const handleTipoIdSelect = (seleccion) => {
    dispatch(filtrarPorEstadosTareas(seleccion));
  };

  useEffect(() => {
    dispatch(startLoadingEstadosTareasOpciones());
  }, [dispatch]);

  return (
    <Select
      className="basic-single"
      classNamePrefix="select"
      isClearable={true}
      isSearchable={true}
      placeholder={"Estados"}
      options={estadosTareasOpciones}
      name={"estadoTareaFilter"}
      value={estadosTareas}
      onChange={(seleccion) => handleTipoIdSelect(seleccion)}
      isMulti={true}
      noOptionsMessage={({ inputValue }) => "No se encontraron resultados"}
    />
  );
};

export default EstadosTareas;
