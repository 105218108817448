import React, { useEffect, useState } from 'react'
import ReactSelect from 'react-select'
import { getAmbitos } from '../../services/ambitoServices';

const Ambitos = ({ value, onChange }) => {

  const [options, setOptions] = useState([]);

  useEffect(() => {
    const abortController = new AbortController();
    getAmbitos(abortController.signal)
      .then((ambitosOptions) => {
        setOptions(ambitosOptions);
      })
      .catch(error => {
        if (error.name !== 'AbortError') {
          throw error
        }
      });

    return () => {
      abortController.abort();
    }
  }, [])

  return (
    <ReactSelect
        className="basic-single"
        classNamePrefix="select"
        isClearable={true}
        isSearchable={true}
        placeholder={"Seleccione Ambito"}
        options={ options }
        name="ambitoId"
        value={ value }
        onChange={ onChange }
    />
  )
}

export default Ambitos