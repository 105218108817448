import React from 'react'
import { useDispatch } from 'react-redux';
import Nav from 'react-bootstrap/Nav';
import NotasSelector from './NotasSelector';
import { ocultarFormularioNueva } from '../../actions/notasActions';

export const NotasSelectores = ({ notas, notaSelected }) => {
    
    const dispatch = useDispatch();
    
    const handleNavClick = () => {
        dispatch(ocultarFormularioNueva())
    };
    
    return (
        <Nav className="border-top flex-column border-end" onSelect={handleNavClick}>
            {
                notas
                    .filter(n => n.show)
                    .map(nota => <NotasSelector key={nota.id} nota={nota} notaSelected={notaSelected} />)
            }
        </Nav>
    )
}

export const NotasSelectoresMemorized = React.memo(NotasSelectores)