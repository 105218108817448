import { useCallback, useState } from "react";


export const useForm = (initialState={})=> { // lo inicializo a un objeto vacio
    const [values, setValues] = useState(initialState)
   
    

    const reset= () => { setValues(initialState) }
    const set = (values) => { setValues(values) }
    
    const handleInputChange = useCallback(({ target }) => {
        // https://github.com/facebook/react/issues/16858#issuecomment-534257343
        setValues(prevValues => {
          return {
            ...prevValues,
            [target.name]: target.value,
          }
        });
      }, []);
      
      return [values, handleInputChange, reset, set ]
}
