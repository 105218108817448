import React from "react";

import Button from "react-bootstrap/Button";

import { Col } from "react-bootstrap";

import { history } from "../../store/store";

const BotonNavegarNotasPdf = () => {
  const handlePdfNotaClick = () => {
    history.push("/notas/exportar");
  };

  return (
    <Col className="mt-3 justify-content-end">
      <Button variant="soliss" size="lg" onClick={handlePdfNotaClick}>
        Mostar notas para exportar
      </Button>
    </Col>
  );
};
export default BotonNavegarNotasPdf;
