const baseUrlToken = process.env.REACT_APP_SOLISSNET_TOKEN_URL;
const baseUrl = process.env.REACT_APP_SOLISSNET_API_URL;
const env = process.env.REACT_APP_ENV;

const fetchToken = (username, password) => {
  const url = `${baseUrlToken}/tokenservice/token`;
  const authHeader = "Basic " + btoa(`${username}:${password}`);

  return fetch(url, {
    headers: {
      Authorization: authHeader,
    },
  });
};

const fetchRefreshToken = () => {
  const tokens = getTokenFromCookie();
  if (tokens && tokens.token) {
    const url = `${baseUrlToken}/b2c/token/refresh`;

    return fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...tokens,
        hard: true,
      }),
    });
  } else if (env === "development") {
    const upn =
      "javiramos@soliss.com                                                                                                                              ";
    const url = `${baseUrlToken}/b2c/token/dev/${upn}`;

    return fetch(url);
  } else {
    return null;
  }
};

export const getTokenFromCookie = () => {
  return {
    token: getCookie("snet.auth.token"),
    refreshToken: getCookie("snet.auth.refresh"),
  };
};

export const setCookieToken = (token, refreshToken) => {
  document.cookie = `snet.auth.token=${token}; path=/; SameSite=Strict; Secure; Max-Age=3600;`;
  document.cookie = `snet.auth.refresh=${refreshToken}; path=/; SameSite=Strict; Secure; Max-Age=14400;`;
};

export const removeTokenCookie = () => {
  document.cookie = `snet.auth.token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; SameSite=Strict; Secure; Max-Age=3600;`;
  document.cookie = `snet.auth.refresh=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; SameSite=Strict; Secure; Max-Age=14400;`;
};

const getCookie = (name) => {
  const value = "; " + document.cookie;
  const parts = value.split("; " + name + "=");
  if (parts.length === 2) return parts.pop().split(";").shift();
};

export const getToken = async (username, password) => {
  const resp = await fetchToken(username, password);

  if (resp.ok) {
    const token = await resp.json();
    setCookieToken(token.token, token.refreashToken);
    return token;
  } else {
    throw new Error(`ERROR getToken. StatusCode:${resp.status}`);
  }
};

export const refreshToken = async () => {
  const resp = await fetchRefreshToken();

  if (resp && resp.ok) {
    const token = await resp.json();
    setCookieToken(token.token, token.refreshToken);
    return token;
  } else if (resp) {
    throw new Error(`ERROR refreshToken. StatusCode:${resp.status}`);
  } else {
    return null;
  }
};

export const getTokenWithAuth = async (auth) => {
  const resp = await fetch(`${baseUrlToken}/tokenservice/token/${auth}`);

  if (resp.ok) {
    const token = await resp.json();
    setCookieToken(token.token, token.refreashToken);
    return token;
  } else {
    throw new Error(`ERROR getToken. StatusCode:${resp.status}`);
  }
};

const fetchConToken = (endpoint, data, method = "GET", signal = null) => {
  const url = `${baseUrl}/${endpoint}`;
  const { token } = getTokenFromCookie();

  if (method === "GET" || method === "HEAD") {
    return fetch(url, {
      headers: { Authorization: "Bearer " + token },
      signal,
    });
  } else {
    return fetch(url, {
      method,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(data),
      signal,
    });
  }
};

export const fetchConTokenYRefresh = async (
  endpoint,
  data,
  method = "GET",
  signal = null
) => {
  let resp = await fetchConToken(endpoint, data, method, signal);

  if (resp.status === 401) {
    await refreshToken();
    resp = await fetchConToken(endpoint, data, method, signal);
  }

  if (resp.ok) {
    try {
      return {
        ok: true,
        json: true,
        data: await resp.json(),
      };
    } catch (error) {
      return {
        ok: true,
        json: false,
      };
    }
  } else {
    const result = await resp.json();

    return {
      ok: false,
      json: false,
      error: result.error,
    };
  }
};
