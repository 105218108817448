import { tareasTypes } from "../types/tareasTypes";

export const mostrarFormularioNueva = () => ({
  type: tareasTypes.mostrarFormulario,
});

export const ocultarFormularioNueva = () => ({
  type: tareasTypes.ocultarFormulario,
});

export const mostrarFormularioNuevaAPartirDeOtra = (id) => ({
  type: tareasTypes.mostrarFormularioNuevaAPartirDeOtra,
  payload: id,
});

export const cargarTareas = (tareas) => ({
  type: tareasTypes.cargarTareas,
  payload: tareas,
});

export const nuevaTarea = (tareasCreadas) => ({
  type: tareasTypes.nuevaTarea,
  payload: tareasCreadas,
});

export const marcarTareaSelected = (id) => ({
  type: tareasTypes.selectedTarea,
  payload: { id },
});

export const seleccionarTareaSiguiente = () => ({
  type: tareasTypes.seleccionarTareaSiguiente,
});

export const seleccionarTareaAnterior = () => ({
  type: tareasTypes.seleccionarTareaAnterior,
});

export const llenarTareaActiva = (id) => ({
  type: tareasTypes.setTareaActiva,
  payload: id,
});

export const marcarTerminada = (id) => ({
  type: tareasTypes.marcarTerminada,
  payload: id,
});

export const marcarCancelada = (id, observaciones) => ({
  type: tareasTypes.marcarCancelada,
  payload: {
    id,
    observaciones,
  },
});

export const marcarPospuesta = (id, fecha, observaciones) => ({
  type: tareasTypes.marcarPospuesta,
  payload: { id, fecha, observaciones },
});

export const getContadorTareas = (contador) => ({
  type: tareasTypes.getContador,
  payload: contador,
});

export const reasignarTarea = (tareaId, destinatarios) => {
  return {
    type: tareasTypes.reasignarTarea,
    payload: {
      tareaId,
      destinatarios,
    },
  };
};

export const rehabilitarTarea = (tareaId) => {
  return {
    type: tareasTypes.rehabilitarTarea,
    payload: {
      tareaId,
    },
  };
};
