import React from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'

const EnviadoEmail = ({ id, email }) => {
  return (
    <OverlayTrigger
        key={id}
        placement="top"
        overlay={
            <Tooltip id={`tooltip-${id}`}>
                {
                    `${email.map(e => e.label).join(',')}`
                }
            </Tooltip>
        }
    >
        <span><i className="fa fa-solid fa-envelope"></i></span>
    </OverlayTrigger>
  )
}

export default EnviadoEmail