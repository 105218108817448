import React from 'react';
import ReactDatePicker from 'react-datepicker';
import { useDispatch, useSelector } from 'react-redux';
import { filtrarPorFecha } from '../../actions/filtrosActions';

const Fecha = () => {

    const dispatch = useDispatch();
    const { fecha } = useSelector(state => state.filtros);

    const handleFechaChange = (fecha) => {
        dispatch(filtrarPorFecha(fecha));
    }

  return (
    <ReactDatePicker
        className="form-control"
        locale="es"
        dateFormat="dd/MM/yyyy"
        selected={fecha}
        placeholderText={"Fecha"}
        name="fecha"
        value={fecha}
        onChange={handleFechaChange}
    />
  )
}

export default Fecha