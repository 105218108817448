import React from "react";
import Button from "react-bootstrap/Button";

const BotonCancelar = ({ setMostrarCancelar }) => {
  const handleClickCancelar = () => {
    setMostrarCancelar((currentState) => !currentState);
  };

  return (
    <Button variant="soliss" onClick={handleClickCancelar}>
      Cancelar
    </Button>
  );
};

export default BotonCancelar;
