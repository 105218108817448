import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "react-bootstrap/Button";
import { getNombreOficina } from "../../services/notasServices";
import { establecerOficinaUsuario } from "../../actions/filtrosActions";

const BotonNotasOficina = () => {
  const { usuarioId, nombre } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [nombreBoton, setNombreBoton] = useState("Ver Notas Oficina");
  const [nombreUsuario, setNombreUsuario] = useState(nombre);

  const handleClick = async () => {
    if (nombreBoton === "Ver Notas Oficina") {
      dispatch(establecerOficinaUsuario(true));
      const nombreOfi = await getNombreOficina();
      setNombreBoton("Ver Notas Usuario");
      setNombreUsuario(nombreOfi);
    } else {
      dispatch(establecerOficinaUsuario(false));
      setNombreBoton("Ver Notas Oficina");
      setNombreUsuario(nombre);
    }
  };
  return (
    <>
      <h3>{`Notas de ${nombreUsuario}`}</h3>
      {usuarioId.startsWith("02") && (
        <Button variant="soliss" size="lg" onClick={handleClick}>
          {nombreBoton}
        </Button>
      )}
    </>
  );
};

export default BotonNotasOficina;
