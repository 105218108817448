import { filtrosTypes } from "../types/filtrosTypes";

const initialState = {
  nueva: false,
  futurasActualesTodas: 1,
  leidasNoleidasTodas: 1,
  privadasPublicas: 1,
  secciones: [],
  seccionesOpciones: [],
  grupos: [],
  gruposOpciones: [],
  ambitos: [],
  ambitosOpciones: [],
  tipos: [],
  tiposOpciones: [],
  estadosTareas: [
    {
      value: 1,
      label: "PENDIENTE",
    },
    {
      value: 3,
      label: "POSPUESTA",
    },
    {
      value: 5,
      label: "CADUCADA",
    },
  ],
  estadosTareasOpciones: [],
  tiposOpcionesPlaceholder: "Tipos notas",
  estadoActualId: 99,
  fecha: new Date(),
  modoLectura: false,
  notasOficina: false,
};

export const filtrosReducer = (state = initialState, action) => {
  switch (action.type) {
    case filtrosTypes.nueva:
      return {
        ...state,
        nueva: action.payload,
      };
    case filtrosTypes.futurasActualesTodas:
      return {
        ...state,
        futurasActualesTodas: action.payload,
      };
    case filtrosTypes.leidasNoleidasTodas:
      return {
        ...state,
        leidasNoleidasTodas: action.payload,
      };
    case filtrosTypes.privadasPublicas:
      return {
        ...state,
        privadasPublicas: action.payload,
      };

    case filtrosTypes.estadoTareas:
      return {
        ...state,
        estadoActualId: action.payload,
      };
    case filtrosTypes.tipos:
      return {
        ...state,
        tipos: action.payload,
      };
    case filtrosTypes.tiposOpciones:
      return {
        ...state,
        tiposOpciones: action.payload,
      };
    case filtrosTypes.seccionesOpciones:
      return {
        ...state,
        seccionesOpciones: action.payload,
      };
    case filtrosTypes.secciones:
      return {
        ...state,
        secciones: action.payload,
      };
    case filtrosTypes.grupos:
      return {
        ...state,
        grupos: action.payload,
      };
    case filtrosTypes.gruposOpciones:
      return {
        ...state,
        gruposOpciones: action.payload,
      };
    case filtrosTypes.ambitos:
      return {
        ...state,
        ambitos: action.payload,
      };
    case filtrosTypes.ambitosOpciones:
      return {
        ...state,
        ambitosOpciones: action.payload,
      };
    case filtrosTypes.fecha:
      return {
        ...state,
        fecha: action.payload,
      };
    case filtrosTypes.notasOficina:
      return {
        ...state,
        notasOficina: action.payload,
      };
    case filtrosTypes.estadosTareas:
      return {
        ...state,
        estadosTareas: action.payload,
      };
    case filtrosTypes.estadosTareasOpciones:
      return {
        ...state,
        estadosTareasOpciones: action.payload,
      };
    case filtrosTypes.modoLectura:
      return {
        ...state,
        modoLectura: action.payload,
      };
    default:
      return state;
  }
};
