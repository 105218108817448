import React, { useEffect, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import Select from "react-select";
import { getEmailsByUsuarioId } from '../../services/usuariosServices';

const EnvioEmail = ({ emailsSelected, destinatarios, handleEmailsChange }) => {

    const [options, setOptions] = useState([]);

    useEffect(() => {
        getEmailsByUsuarioId(destinatarios)
            .then(emails => {
                const emailOptions = emails.map(email => ({
                    value: email,
                    label: email
                }));
                setOptions(emailOptions)
            })
    }, [destinatarios])
    
    useEffect(() => {
        if (emailsSelected && emailsSelected instanceof Array) {
            const emails = emailsSelected.map(e => e.value);
            const emailsOpt = options.map(e => e.value);
            const seHanEliminadoDestinatarios = emails.some(e => !emailsOpt.includes(e));
    
            if(seHanEliminadoDestinatarios){
                const newEmailsSelected = emailsSelected.filter(e => emailsOpt.includes(e.value));
                handleEmailsChange(newEmailsSelected);
            }
        }
    }, [emailsSelected, options, handleEmailsChange]);

    return (
        <Form.Group as={Row} className="mt-1" id="enviarEmail">
            <Form.Label column lg="2">Emails</Form.Label>
            <Col lg="10">
                <Select
                    className="basic-single"
                    classNamePrefix="select"
                    isClearable={true}
                    isSearchable={true}
                    placeholder="Destinatarios de Correo Electrónico" 
                    options={options}
                    name="email"
                    value={emailsSelected}
                    onChange={handleEmailsChange}
                    isMulti={true}
                    noOptionsMessage={({inputValue}) => "No se encontraron resultados"}
                />
            </Col>
        </Form.Group>
    )
}

export default EnvioEmail