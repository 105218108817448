import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Stack from "react-bootstrap/Stack";
import Swal from "sweetalert2";
import DatePicker, { registerLocale } from "react-datepicker";
import { startOfTomorrow } from "date-fns";
import es from "date-fns/locale/es";
import "react-datepicker/dist/react-datepicker.css";

import RadioSoliss from "../ui/RadioSoliss";
import { opcionesPrivada } from "../../options/opcionesPrivada";
import { useDispatch } from "react-redux";
import { useTareas } from "../../hooks/useTareas";
import { startCrearTarea } from "../../middlewares/tareas";
import EnvioEmail from "../shared/EnvioEmail";
import Destinatarios from "../shared/Destinatarios";
import Grupo from "../shared/Grupo";
import MensajeTexto from "../shared/MensajeTexto";
import BotonesNueva from "../shared/BotonesNueva";

registerLocale("es", es);
const TareaNueva = ({ gruposOptions }) => {
  const { tarea, handleInputChange, limpiar } = useTareas({});
  const dispatch = useDispatch();
  const [grupoSelected, setGruposSelectedOption] = useState(
    tarea.grupoSelected
  );

  const handleNotaInputChange = useCallback(
    (name, value) => {
      handleInputChange({ target: { name, value } });
    },
    [handleInputChange]
  );

  const handleGrupoIdSelect = (seleccion) => {
    setGruposSelectedOption(seleccion);
    handleNotaInputChange("grupoId", seleccion.value);
  };

  const handleDestinatarioSelect = (selecciones) => {
    if (selecciones) {
      handleNotaInputChange("destinatarios", selecciones);
    } else {
      handleNotaInputChange("destinatarios", []);
    }
  };

  const handleFechaInicioChange = (fecha) => {
    handleNotaInputChange("fechaInicio", fecha);
  };

  const handleFechaCaducidadChange = (fecha) => {
    handleNotaInputChange("fechaCaducidad", fecha);
  };

  const handleEmailsChange = useCallback(
    (emailsSelected) => {
      if (emailsSelected) {
        handleNotaInputChange("email", emailsSelected);
      } else {
        handleNotaInputChange("email", []);
      }
    },
    [handleNotaInputChange]
  );

  const handleCheckPrivada = (privada) => {
    handleNotaInputChange("privado", privada);
  };

  const handleSubmit = (ev) => {
    ev.preventDefault();

    if (!tarea.destinatarios || tarea.destinatarios.length < 1) {
      return Swal.fire("Error", "Debe indicar un destinatario", "error");
    }

    if (tarea.texto.trim().length === 0) {
      return Swal.fire("Error", "Debe escribir una anotación", "error");
    }

    if (!tarea.fechaCaducidad || !(tarea.fechaCaducidad instanceof Date)) {
      return Swal.fire(
        "Error",
        "Debe seleccionar una fecha de caducidad válida",
        "error"
      );
    }

    if (
      tarea.fechaEnvio >= startOfTomorrow() &&
      tarea.email &&
      tarea.email.length > 0
    ) {
      return Swal.fire(
        "Error",
        "No se puede enviar email en notas futuras.",
        "error"
      );
    }

    dispatch(startCrearTarea(tarea, limpiar));
  };

  return (
    <Form autoComplete="off" onSubmit={handleSubmit}>
      <Destinatarios
        destinatariosSelected={tarea.destinatarios}
        handleDestinatarioSelect={handleDestinatarioSelect}
      />

      {tarea.destinatarios.length > 0 && (
        <Row className="mt-3">
          <Col>
            <EnvioEmail
              emailsSelected={tarea.email}
              destinatarios={tarea.destinatarios}
              handleEmailsChange={handleEmailsChange}
            />
          </Col>
        </Row>
      )}

      <Form.Group as={Row} className="mt-1 mb-3" id="categorias">
        <Stack className="mt-2" direction="horizontal" gap={3}>
          <div>
            <RadioSoliss
              name="privado"
              value={tarea.privado}
              onChange={handleCheckPrivada}
              options={opcionesPrivada}
            />
          </div>
        </Stack>
      </Form.Group>

      <Row>
        <Col lg="2">
          <Form.Label lg="2">Fecha de inicio</Form.Label>
          <DatePicker
            className="form-control"
            locale="es"
            dateFormat="dd/MM/yyyy"
            selected={tarea.fechaInicio}
            minDate={new Date()}
            placeholderText={"Fecha de inicio"}
            name="fechaInicio"
            value={tarea.fechaInicio}
            onChange={handleFechaInicioChange}
          />
        </Col>

        <Col lg="2">
          <Form.Label lg="2">Fecha de caducidad</Form.Label>
          <DatePicker
            className="form-control"
            locale="es"
            dateFormat="dd/MM/yyyy"
            selected={tarea.fechaCaducidad}
            minDate={new Date()}
            placeholderText={"Fecha de caducidad"}
            name="fechaCaducidad"
            value={tarea.fechaCaducidad}
            onChange={handleFechaCaducidadChange}
          />
        </Col>

        <Col lg="4">
          <Grupo
            gruposOptions={gruposOptions}
            grupoSelected={grupoSelected}
            handleGrupoIdSelect={handleGrupoIdSelect}
            titulo="Tipo de tarea"
          />
        </Col>
      </Row>

      <Form.Group className="mt-3" controlId="exampleForm.ControlTextarea1">
        <MensajeTexto texto={tarea.texto} onChange={handleInputChange} />
      </Form.Group>

      <BotonesNueva onClickLimpiar={limpiar} />
    </Form>
  );
};

TareaNueva.propTypes = {
  gruposOptions: PropTypes.array.isRequired,
};

export default TareaNueva;
