import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import format from "date-fns/format";
import es from "date-fns/locale/es";
import { DestinatariosPdf } from "./DestinatariosPdf";

const NotasSelectorPdf = ({ nota }) => {
  const fechaDetalle = format(nota.fecha, "EEEE dd/MM/yyyy hh:mm", {
    locale: es,
  });
  return (
    <div>
      <Row>
        <Col>
          <small>
            <b>
              {nota.ambito.id}: {nota.referenciaId}
            </b>
          </small>
        </Col>
        <Col lg="4" className="text-end">
          <span>
            <b>{nota.grupo.nombre}</b>
          </span>
        </Col>
      </Row>

      <Row>
        <Col lg="1"></Col>
        <Col lg="6">
          <small>
            <b>
              De: {nota.remitente} {nota.de}
            </b>
          </small>
        </Col>
        <Col lg="2">
          <small> EXT.:{nota.remitenteExtension}</small>
        </Col>
        <Col lg="3" className="text-end">
          <small className="">{fechaDetalle}</small>
        </Col>
      </Row>
      {nota.destinatarios.map((destinatario) => (
        <DestinatariosPdf
          Key={destinatario.value}
          destinatario={destinatario}
        />
      ))}

      <Row style={{ marginTop: "-10px" }}>
        <Col lg="1"></Col>
        <Col>
          <hr className="style10" />
        </Col>
      </Row>
      <Row style={{ marginTop: "-10px" }}>
        <Col lg="1"></Col>
        <Col lg="11" className="">
          <small dangerouslySetInnerHTML={{ __html: nota.texto }} />
        </Col>
      </Row>
      <hr />
    </div>
  );
};

export default NotasSelectorPdf;
