import React from 'react'
import { Button, Col, Row } from 'react-bootstrap'

const BotonesNueva = ({ onClickLimpiar }) => {
  return (
    <Row>
        <Col sm="1">
            <Button className="mt-2" variant="soliss" type="submit">
                Enviar
            </Button>
        </Col> 
        <Col sm="1">
            <Button className="mt-2" variant="secondary" onClick={onClickLimpiar}>
                Limpiar
            </Button>
        </Col> 
    </Row >
  )
}

export default BotonesNueva