import React from 'react'
import { useDispatch } from 'react-redux';
import { startReasignarTarea } from '../../middlewares/tareas';
import Destinatarios from '../shared/Destinatarios'

const ReasignarTarea = ({ tareaId, tareaDestinatarios }) => {

    const dispatch = useDispatch();

    const handleDestinatarioSelect = (seleccion) => {
        dispatch(startReasignarTarea(tareaId, seleccion));
    }

    return (
        <Destinatarios
            destinatariosSelected={ tareaDestinatarios }
            handleDestinatarioSelect={ handleDestinatarioSelect }
        />
    )
}

export default ReasignarTarea