export const filtrosTypes = {
  nueva: "FILTROS_NUEVA",
  esTarea: "FILTROS_ESTAREA",
  futurasActualesTodas: "FILTROS_FUTURAS_ACTUALES_TODAS",
  leidasNoleidasTodas: "FILTROS_LEIDAS_NOLEIDASS_TODAS",
  privadasPublicas: "FILTROS_PRIVADAS_PUBLICAS",
  seccionesOpciones: "FILTROS_SECCIONES_OPCIONES",
  secciones: "FILTROS_SECCIONES",
  grupos: "FILTROS_TIPOS",
  gruposOpciones: "FILTROS_TIPOS_OPCIONES",
  ambitos: "FILTROS_AMBITOS",
  ambitosOpciones: "FILTROS_AMBITOS_OPCIONES",
  tipos: "FILTROS_TIPOS_TAREA",
  tiposOpciones: "FILTROS_TIPOS_TAREA_OPCIONES",
  estadosTareas: "FILTROS_ESTADOS_TAREA",
  estadosTareasOpciones: "FILTROS_ESTADOS_TAREA_OPCIONES",
  estadoTareas: "FILTROS_ESTADO_TAREAS",
  fecha: "FILTROS_FECHA",
  modoLectura: "FILTROS_MODO_LECTURA",
  notasOficina: "FILTROS_USUARIO_OFICINA",
};
